import React, { useState, useEffect, useContext, useRef } from "react";
import $ from 'jquery';
import Select from "react-select";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Popup from 'reactjs-popup';
import AddlVigDialog from '../components/addlvignettes';
// import { readRecordJoin, createRecord, readRecord, updateRecord, sendEmail, createPdf, digitollPost, readCDValuesByCode } from "../Redux/Action/Action";
import { createRecord, readRecord, updateRecord, sendEmail, createPdf, digitollPost, readCDValuesByCode, verifyEmailCode } from "../Redux/Action/Action";
import { successToast, warningToast, errorToast } from '../components/toastify';
import PayPal from '../components/PayPal';
import DatePicker from "react-datepicker";
//import { DateRangePicker } from 'react-date-range';
// import { DateRangePicker } from "date-range-react-picker";
// import StripeCheckout from 'react-stripe-checkout';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import AppContext from '../appContext';
import PaymentForm from '../components/PaymentForm';
import SecretForm from '../components/SecretForm';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useTranslation } from 'react-i18next';
import data from '../data.json'; 
import envData from '../env.json'; 
import ApplePay from "../components/ApplePay";
import MyGooglePay from "../components/GooglePay";
import VignetteView from "../components/VignetteView";
import TermsConditions from './footer_pages/terms_conditions';
// import 'react-date-range/dist/styles.css'; // main style file
// import 'react-date-range/dist/theme/default.css'; 

const stripePk = envData.stripePk;
const licensePlateData = require('../assets/files/licensePlate.json');
const stripePromise = loadStripe(stripePk);
const imageURL = "https://d2m4rx9jjmzfgf.cloudfront.net/";

export default function Order() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const licensePlatePattern = /^(?=.*[A-Za-z\d])[A-Za-z\d—ÜÖÄČŠŽĆĐ]{1,15}$/;
    // const passwordPattern = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d\S]{8,1000}$/;
    const passwordPattern = /^(?=.*[A-Za-z])(?=.*\d)([A-Za-z\d\s\?]{8,1000})$/;
    let minDate = new Date();
    let maxDate = new Date();
    minDate.setDate(minDate.getDate());
    maxDate.setDate(maxDate.getDate() + 30);
    const colourStyles = {
        control: styles => ({ ...styles, border: '1px solid #97a5ba' })
    }
    let { homedata } = useSelector(state => state.data);
    const { isLoggedIn, setIsLoggedIn, exchangeRates, defaultCurrency, orderSaveAsDraft, setOrderSaveAsDraft } = useContext(AppContext);
    homedata = homedata.length ? homedata[(homedata.length - 1)] : {};

    const nextButtonRef = useRef(null);

    {/* const [userState, setUserState] = useState({
        startDate: new Date(),
        endDate: addDays(new Date(), 7)
        }
    );

    const selectionRange = {
        startDate: new Date(),
        endDate: addDays(new Date(),7),
        key: 'selection',
      }

    const handleSelect = (ranges) => {
        console.log(ranges);
    }
    
    const handleDateChange = (date) => {
        setUserState(date);
    }

    useEffect(() => console.log("UserState", userState), [userState]);

    */}

    function addDays(date, days) {
        const copy = new Date(date.getTime());
        copy.setDate(copy.getDate() + days);
        return copy;
      }
      
    let hasRun = useRef(false);
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [totalCost, setTotalCost] = useState(0);
    const [vignetteCost, setVignetteCost] = useState(0);
    const [isPaymentReady, setIsPaymentReady] = useState(false);
    const [showOtp, setShowOtp] = useState(false);
    const [otpVerified, setOtpVerified] = useState(false);
    const [selCurrency, setSelCurrency] = useState("EUR");

    const [countryOptions, setCountry] = useState(data.Countries);
    const [allVehicles, setAllVehicles] = useState(data.Vehicles);
    const [allSettings, setAllSettings] = useState(data.Settings);
    const [vehicleCatOptions, setVehicleCat] = useState([]);
    const [allDurations, setAllDurations] = useState(data.Durations);
    const [durationOptions, setDuration] = useState([]);

    const [addlVignettes, setAddlVignettes] = useState([]);

    const [selectedCountry, setSelectedCountry] = useState({ _id: '', value: '', filename: '',processing: '' });
    const [selectedVehicle, setSelectedVehicle] = useState({ _id: '', value: '', filename: '' });
    const [selectedDuration, setSelectedDuration] = useState({ _id: '', value: '', absolute_value: 1, price: 0 });
    const [registrationCountry, setRegistrationCountry] = useState(null);
    const [myAllVehicle, setMyAllVehicle] = useState([]);
    const [mySelectedVehicle, setMySelectedVehicle] = useState(null);
    const [customerVehicleType, setCustomerVehicleType] = useState("");
    const [currVehicleImage, setCurrVehicleImage] = useState(null);
    const [currentDuration, setCurrentDuration] = useState("");
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [tempStartDate, setTempStartDate] = useState(null); // It's temp date
    const [timeStamp, setTimeStamp] = useState(minDate.toLocaleString('en-GB', { hour: 'numeric', minute: 'numeric', hour12: true })); // It's Time Stamp
    const [stepOne, setStepOne] = useState("active");
    const [stepTwo, setStepTwo] = useState("");
    const [stepThree, setStepThree] = useState("");
    const [stepFour, setStepFour] = useState("");
    const [license_plate, setLicense_plate] = useState({ license_plate: "", clicense_plate: "" });
    const [userInfo, setUserInfo] = useState({ email: "", confirm_email: "", password: "", guest_email: "", emailCode: "" });
    const [acceptTerms, setAcceptTerms] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isBuyNow, setIsBuyNow] = useState(false);
    const [isLoading1, setIsLoading1] = useState(true);
    const [showCheckout, setShowCheckout] = useState(false);
    const [loginUser, setLoginUser] = useState({});
    const [isMobileView, setIsMobileView] = useState(false);
    const [isMobileSkeleton, setIsMobileSkeleton] = useState(false);
    const [serviceCharge, setServiceCharge] = useState(0);
    const [flexiChange, setFlexiChange] = useState(0);
    const [flexiTepmChange, setTepmFlexiChange] = useState(0);
    
    // for error handle
    const [isStepOneError, setStepOneError] = useState(false);
    const [isStepTwoError, setStepTwoError] = useState(false);
    const [isStepThreeError, setStepThreeError] = useState(false);
    const [licensePlateerror, setLicensePlateerror] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [isPassOneError, setIsPassOneError] = useState(false);

    const [activeTab, setActiveTab] = useState(1);
    const [benefitsBy, setBenefitsBy] = useState("");

    const [showPopup, setShowPopup] = useState(false);
    const [showPaymentForm, setShowPaymentForm] = useState(false);
    const [showSecretForm, setShowSecretForm] = useState(false);

    const handleShowSecretForm = () => {
        setShowSecretForm(true);
        setShowPaymentForm(false);
    }    

    const handleShowPaymentForm = () => {
        setShowPaymentForm(true);
        setShowSecretForm(false);
    }    

    const openPopup = () => {
      setShowPopup(true);
    };
  
    const closePopup = (vigInfo) => {
      setShowPopup(false);
      if (vigInfo != null) {
        let tempVignettes = [ ...addlVignettes ];
        tempVignettes.push(vigInfo);
        setAddlVignettes(tempVignettes);
      }
    };
  
    useEffect(() => {
        // setSaveAsDraft('','');
        if (!hasRun.current) {
            hasRun.current = true;
        }
        else {
            return;  // this is real important, otherwise already selected values are getting messed-up
        }
        if (localStorage.getItem('saveAsDraft')) {
            console.log("we are here 1");
            homedata = (JSON.parse(localStorage.getItem('saveAsDraft')))
            console.log("we are here 2",homedata);
            setSaveAsDraft('activeTab', homedata.activeTab);
            if (homedata.selectedVehicle) {
                setSelectedVehicle(homedata.selectedVehicle);
            }
            if (homedata.addlVignettes) {
                // setAddlVignettes([]);
                setAddlVignettes(homedata.addlVignettes);
                console.log("we are here 3",homedata.addlVignettes.length);
                {/*
                setStepOne(homedata.stepOne)
                setStepTwo(homedata.stepTwo)
                setStepOne(homedata.stepThree);
                setStepFour(homedata.stepFour);    
                setActiveTab(homedata.activeTab);
                setShowCheckout(homedata.showCheckout);
                setServiceCharge(allSettings.serviceCharge); */}
            }
            else
                setAddlVignettes([]);
        }
        getVehicleCat();
        if (homedata.vehiclecategory) {    
            if (homedata.start_date) {
                setTempStartDate(homedata.start_date);
                setStartDate(new Date((homedata.start_date).split('/').reverse().join('/')));
                handlerFlexiChange(new Date((homedata.start_date).split('/').reverse().join('/')));
            }
            if (homedata.end_date) {
                setEndDate(new Date((homedata.end_date).split('/').reverse().join('/')));
            }
            if (homedata.flex_service_charge) {
                setFlexiChange(homedata.flex_service_charge);
            }
            if (homedata.timeStamp) {
                setTimeStamp(homedata.timeStamp);
            }
            if (homedata.isBuyNow) {
                setIsBuyNow(homedata.isBuyNow);
            }
            setLicense_plate(license_plate => ({ ...license_plate, license_plate: homedata?.license_plate?.license_plate, clicense_plate: homedata?.license_plate?.clicense_plate }));
            setStepOne("")
            setStepTwo("")
            setStepOne("");
            setStepFour("");
            if (homedata.activeTab) {
                if (homedata.activeTab === 1) {
                    setStepOne("active show");
                }
                if (homedata.activeTab === 2) {
                    setStepTwo("active show");
                }
                if (homedata.activeTab === 3) {
                    setStepThree("active show");
                }
                if (homedata.activeTab === 4) {
                    setStepFour("active show");
                    setShowCheckout(true);
                }
                setActiveTab(homedata.activeTab);
            } else {
                setActiveTab(1);
                setStepOne("active show");
            }
            if (homedata.vehicle_country_of_registration) {
                setRegistrationCountry(homedata.vehicle_country_of_registration)
                // setActiveTab(3); // for previous step active
                // setStepThree("active show");
            }
        }
        const logUser = localStorage.getItem('vignetteLoginUser') ? JSON.parse(localStorage.getItem('vignetteLoginUser')) : {};
        setLoginUser(logUser);
        if (logUser && logUser._id) {
            setIsLoggedIn(true);
            if (logUser.emailCode) {
                // setShowOtp(true);  27-06 as per Br Nur, disable it for now
                setOtpVerified(false);
            }
            setUserInfo(userInfo => ({ ...userInfo, email: logUser['email'], confirm_email: logUser['email'], password: logUser['password'] }));
        }
    }, [])

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth < 768);
            setIsMobileSkeleton(window.innerWidth <= 441)
        };

        for (let i in licensePlateData) {
            licensePlateData[i].value = licensePlateData[i].id;
        }

        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            // localStorage.setItem("vignetteLoginUser", JSON.stringify(res.data[0]))
            window.removeEventListener('resize', handleResize);
        };

    }, [])

    useEffect(() => {
        setSelCurrency(exchangeRates.code);
    }, [exchangeRates])

    useEffect(() => {
        calcTotalCost();
        calcVignetteCost();
        setSaveAsDraft('addlVignettes', addlVignettes);        
    }, [addlVignettes])

    useEffect(() => {
        if (currentDuration && currentDuration != '') {
            changeEndDateAsPerDuration(startDate);
        }
        setSaveAsDraft('startDate', startDate);
    }, [currentDuration, startDate])

    useEffect(() => {
        setSaveAsDraft('endDate', endDate);
    }, [endDate])

    useEffect(() => {
        if (selectedVehicle.filename == '') {
            setCurrVehicleImage("/assets/images/empty-vehicle.png");
        }
        else {
            setCurrVehicleImage(imageURL+selectedVehicle.filename);
        }
        setSaveAsDraft('selectedVehicle', selectedVehicle);
    }, [selectedVehicle])

    useEffect(() => {
        if (currVehicleImage) {
            let copyArr = [...addlVignettes];
            const firstElem = copyArr[0];
            firstElem.vehicleImage = currVehicleImage;
            setAddlVignettes(copyArr);        
        }
    }, [currVehicleImage])

    useEffect(() => {
        calcTotalCost();
    }, [serviceCharge])

    useEffect(() => {
        {/*
        if (vehicleCatOptions && vehicleCatOptions.length > 0) {
            changeVehicleClick(0);
            if (durationOptions && durationOptions.length > 0) {
                changeDuration(0);
                nextButtonRef.current.scrollIntoView({ behavior: 'smooth' }); // Smooth scrolling
                // nextButtonRef.current.focus();
            }
        }
        */}
    }, [vehicleCatOptions])

    useEffect(() => {
        {/*
        if (durationOptions && durationOptions.length > 0) {
            changeDuration(0);
        }
        */}
    }, [durationOptions])

    useEffect(() => {
        if (license_plate && license_plate.license_plate) {
            let copyArr = [...addlVignettes];
            const firstElem = copyArr[0];
            firstElem.licensePlate = license_plate.license_plate;
            setAddlVignettes(copyArr);        
        }
    },[license_plate]);

    useEffect(() => {
        if (registrationCountry) {
            let copyArr = [...addlVignettes];
            const firstElem = copyArr[0];
            console.log("registrationCountry got selected",registrationCountry);
            firstElem.regCountryCode = registrationCountry.code.toUpperCase();
            firstElem.regCountryName = registrationCountry.label;
            firstElem.regCountryFlag = imageURL + registrationCountry.flag.img;
            firstElem.regBackColor = registrationCountry.flag.color;
            setAddlVignettes(copyArr);        
        }
    },[registrationCountry]);

    // Get vehicle category
    function getVehicleCat() {
        console.log("Is this first time entry? ",addlVignettes.length);
        //
        // Very first time, initialize the array and then add 1st element
        //
        var vigArray = [];
        let vigInfo = null;
        let selCtry = null;
        var newVig = false;
        //
        if (homedata.addlVignettes && homedata.addlVignettes.length > 0) {            
            vigArray = homedata.addlVignettes;
            console.log("homedata.addlVignettes",vigArray.length);
            vigInfo = vigArray[0];
            homedata.country = vigInfo.countryId;
            selCtry = countryOptions.find((country) => country._id === homedata.country);
            newVig = false;
            setStartDate(new Date(vigInfo.startDate));
            if (homedata.vehicle_country_of_registration) {
                setRegistrationCountry(homedata.vehicle_country_of_registration);
            }
            if (homedata.license_plate) {
                setLicense_plate(homedata.license_plate);
            }
        }
        else {
            console.log("Nothing new array");
            //    
            selCtry = countryOptions.find((country) => country._id === homedata.country);
            vigInfo = {
                countryId: selCtry._id,
                vehicleId: "",
                durationId: "",    
                countryName: selCtry.value,
                durationName: "",
                price: "",
                priceNum: 0,
                flexiCharge: 0,
                startDate: startDate, // startDate!=null?startDate.toLocaleDateString('en-GB'):"",
                endDate: endDate, // endDate!=null?endDate.toLocaleDateString('en-GB'):"",
                vehicleImage: "",
                vehicleName: "",
                licensePlate: "",
                regCountryName: "",
                regCountryCode: "",
                regCountryFlag: "/assets/images/place-holder.png",
                regBackColor: "",
                countryFilename: selCtry.filename,
                processing: selCtry.processing,
                isBuyNow: false
            };
            newVig = true;
            console.log("newVig is being added",vigInfo);
        }
        setSelectedCountry({_id:homedata.country, value: selCtry.value, filename: selCtry.filename, processing: selCtry.processing});
        setSaveAsDraft('country', homedata.country || selectedCountry._id);
        const vehForCtrs = allVehicles.filter((oneVeh) => oneVeh.Countries.includes(homedata.country));
        setVehicleCat(vehForCtrs);  
        //
        if (homedata.vehiclecategory && homedata.vehiclecategory != '') {
            if (vehicleCatOptions && vehicleCatOptions.length > 0) {
                let selVeh = vehicleCatOptions.find((vehCat) => vehCat._id === homedata.vehiclecategory);
                if (selVeh == null) {
                    selVeh = vehicleCatOptions[0];
                }
                setSelectedVehicle({_id: homedata.vehiclecategory, value: selVeh.value, filename: selVeh.filename });
                vigInfo.vehicleName = selVeh.value;
                vigInfo.vehicleImage = imageURL+selVeh.filename;
                vigInfo.vehicleId = selVeh._id;
            }
        }
        if (homedata.duration && homedata.duration != '') {
            const selDur = allDurations.find((vehDur) => vehDur._id === homedata.duration);
            setSelectedDuration({_id: homedata.duration, value: selDur.value, absolute_value: selDur.absolute_value, price: selDur.price });
            vigInfo.durationName = selDur.value;
            if (exchangeRates && exchangeRates.price)
                vigInfo.price = (selDur.price * exchangeRates.price).toFixed(2);
            else
                vigInfo.price = (selDur.price * 1).toFixed(2);
            vigInfo.priceNum = selDur.price;
            vigInfo.durationId = selDur._id;
        }
        setIsLoading(false);
        setIsLoading1(false);
        //
        if (newVig) {
            vigArray.push(vigInfo);
        }
        setAddlVignettes(vigArray);
        calcTotalCost();
        calcVignetteCost();
    }

    // get my vehicles
    const getMyVehicles = (item) => {
        readCDValuesByCode({ code: ['GLOBALVEHICLETYP'] }).then(async (vehclRes) => {
            let Body = {
                "table": "customer_vehicles",
                "selectFields": { customerId: 1, deleted: 1, status: 1, country: 1, vehicle_number: 1, vehicle_type: 1 },
                "whereCondition": {
                    customerId: loginUser['_id'],
                    country: item.label,
                    deleted: null,
                    status: "ACTV",
                },
                "sortBy": {
                    "createdAt": "-1"
                },
                "limit": "0",
                "populate": [{ path: "vehicle_type", select: "value code" }],
                "page": "1"
            }
            for (let i in vehclRes.data['GLOBALVEHICLETYP']) {
                if (vehclRes.data['GLOBALVEHICLETYP'][i].value === selectedVehicle.value) {
                    Body.whereCondition.vehicle_type = vehclRes.data['GLOBALVEHICLETYP'][i]._id;
                    setCustomerVehicleType(vehclRes.data['GLOBALVEHICLETYP'][i]._id);
                }
            }
            readRecord(Body).then(async (res) => {
                for (let i in res.data) {
                    res.data[i].label = res.data[i].vehicle_number;
                    res.data[i].value = res.data[i].vehicle_number;
                    if (res.data[i].vehicle_country_of_registration) {
                        res.data[i].vehicle_country_of_registration = JSON.parse(res.data[i].vehicle_country_of_registration);
                    }
                }
                setMyAllVehicle(res.data);
                setIsLoading(false)
            })
        })
    }
    const changeVehicleClick = (index) => {
        let clonedata = [...vehicleCatOptions];
        if (startDate == null) {
            buyImmediately();
        }
        setSelectedVehicle({ ...selectedVehicle, _id: clonedata[index]._id, value: clonedata[index].value, filename: clonedata[index].filename });
        setSaveAsDraft('vehiclecategory', clonedata[index]._id);
        setSaveAsDraft('duration', "");
         setSaveAsDraft('startDate', "");
         setSaveAsDraft('endDate', "");
        setSelectedDuration({ _id: '', value: '', absolute_value: 1, price: 0 });
        setMyAllVehicle([]);
        setIsLoading1(true);
        setBenefitsBy("");
        //
        // Retrieve 1st Vignette Info from the array and then modify
        //
        let copyArr = [...addlVignettes];
        if (copyArr.length <= 0) {
            const vigInfo = {
                countryId: selectedCountry._id,
                vehicleId: "",
                durationId: "",        
                countryName: selectedCountry.value,
                durationName: "",
                price: "",
                priceNum: 0,
                flexiCharge: 0,
                licensePlate: "",
                regCountryName: "",
                regCountryCode: "",
                regCountryFlag: "/assets/images/place-holder.png",
                regBackColor: "",
                startDate: startDate, // startDate!=null?startDate.toLocaleDateString('en-GB'):"",
                endDate: endDate, // endDate!=null?endDate.toLocaleDateString('en-GB'):"",
                vehicleImage: "",
                vehicleName: "",
                countryFilename: selectedCountry.filename,
                processing: selectedCountry.processing,
                isBuyNow: false
              };
              copyArr.push(vigInfo);
        }
        const firstElem = copyArr[0];
        if (firstElem != null) {
            firstElem.vehicleName = clonedata[index].value;
            firstElem.vehicleImage = imageURL+clonedata[index].filename;
            firstElem.vehicleId = clonedata[index]._id;
            copyArr[0] = firstElem;
            setAddlVignettes(copyArr);
            calcTotalCost();
            calcVignetteCost();    
        }
        //
        let Body2 = {
            "table": "codedomains",
            "selectFields": {},
            "whereCondition": {
                "code": "DURATION"
            },
            "sortBy": {},
            "limit": "1",
            "populate": [],
            "page": "1"
        }
        let Body3 = {
            "table": "related_domains",
            "selectFields": {},
            "whereCondition": {
                "codedomain_values1": "ObjectId-" + clonedata[index]._id,
                "codedomain2": ""
            },
            "sortBy": {},
            "limit": "0",
            "populate": [{ path: 'codedomain_values2', select: 'value code filename absolute_value status deleted price' }],
            "page": "1"
        }
        const vehDurKey = clonedata[index]._id;
        const selDur = allDurations.filter((duration) => duration.Vehicles.includes(vehDurKey));
        if (selDur) {
           const sortedSelDur = selDur.sort((a, b) => a.days - b.days);
           setDuration(sortedSelDur);
           if (homedata.duration && homedata.duration != '') {
              const selDur2 = selDur.find((vehDur) => vehDur._id === homedata.duration);
              if (selDur2) {
                 setSelectedDuration(selDur2);
              }
           }
        }
        else
           setDuration([]);
        setIsLoading1(false);
    }

    const setSaveAsDraft = (key, value) => {
        let tempSaved = {};
        if (localStorage.getItem('saveAsDraft')) {
            tempSaved = JSON.parse(localStorage.getItem('saveAsDraft'));
        }
        tempSaved[key] = value;
        localStorage.setItem("saveAsDraft", JSON.stringify(tempSaved));
        setOrderSaveAsDraft(tempSaved);
    }

    const getNextSaturday = () => {
        const date = startDate?startDate:new Date();
        const day = date.getDay();
        const daysToAdd = (6 - day + 7) % 7; // Calculate days to add to reach next Saturday
    
        const nextSaturday = new Date(date.getTime());
        nextSaturday.setDate(date.getDate() + daysToAdd);
    
        return nextSaturday;
    }

    // change duration
    const changeDuration = (index) => {
        let clonedata = [...durationOptions];
        setCurrentDuration(clonedata[index].value);
        setSelectedDuration(selectedDuration => ({ ...selectedDuration, _id: clonedata[index]._id, value: clonedata[index].value, absolute_value: clonedata[index].absolute_value, price: clonedata[index].price }));
        setSaveAsDraft('duration', clonedata[index]._id);
        //
        // Retrieve 1st Vignette Info from the array and then modidy
        //
        let copyArr = [...addlVignettes];
        if (copyArr.length <= 0) {
            const vigInfo = {
                countryId: selectedCountry._id,
                vehicleId: selectedVehicle._id,
                durationId: "",        
                countryName: selectedCountry.value,
                durationName: "",
                price: "",
                priceNum: 0,
                licensePlate: "",
                regCountryName: "",
                regCountryCode: "",
                regCountryFlag: "/assets/images/empty-vehicle.png",
                regBackColor: "",
                flexiCharge: 0,
                startDate: startDate, // startDate!=null?startDate.toLocaleDateString('en-GB'):"",
                endDate: endDate, // endDate!=null?endDate.toLocaleDateString('en-GB'):"",
                vehicleImage: imageURL+selectedVehicle.filename,
                vehicleName: selectedVehicle.value,
                countryFilename: selectedCountry.filename,
                processing: selectedCountry.processing,
                isBuyNow: false
              };
              copyArr.push(vigInfo);
        }
        const firstElem = copyArr[0];
        if (firstElem != null) {
            if (!firstElem.startDate) {
                firstElem.startDate = startDate;
            }
            firstElem.durationName = clonedata[index].value;
            firstElem.price = (clonedata[index].price * exchangeRates.price).toFixed(2);
            firstElem.priceNum = clonedata[index].price;
            firstElem.durationId = clonedata[index]._id;
            copyArr[0] = firstElem;
            setAddlVignettes(copyArr);
            calcTotalCost();
            calcVignetteCost();    
        }
        if (clonedata[index].value === "Weekend") {
            setBenefitsBy("");
            setIsBuyNow(false);
            setSaveAsDraft('isBuyNow', false);
            let nextWeekEndDate = getNextSaturday();
            setTempStartDate(nextWeekEndDate);
            setStartDate(nextWeekEndDate);
            setSaveAsDraft('start_date', nextWeekEndDate);
            setTimeStamp("");
            if (firstElem != null) {
                firstElem.startDate = nextWeekEndDate;
                copyArr[0] = firstElem;
                setAddlVignettes(copyArr);
            }
        }
    }

    // change country of registration
    const changeRegCountry = (event) => {
        setRegistrationCountry(event);
        if (isLoggedIn) {
            if (loginUser.emailCode) {
                // setShowOtp(true);  27-06 as per Br Nur, disable it for now
                setOtpVerified(false);
            }
            getMyVehicles(event);
        }
        setSaveAsDraft('vehicle_country_of_registration', event);
    }

    // Selete My Vehicle
    const changeMyVehicle = (event) => {
        setMySelectedVehicle(event);
        setLicense_plate(license_plate => ({ ...license_plate, license_plate: event.vehicle_number, clicense_plate: event.vehicle_number }));
        setSaveAsDraft('license_plate', {
            license_plate: event.vehicle_number,
            clicense_plate: event.vehicle_number,
        })
        // setSaveAsDraft('vehicle_country_of_registration', event);
    }

    // change license
    const changeLicense = (e) => {

        const { name, value } = e.target;
        setLicense_plate(license_plate => ({ ...license_plate, [name]: value.toUpperCase() }));

        // Start For refrash SaveAsDraft
        let ddd = {
            license_plate: "",
            clicense_plate: "",
        };
        if (name === 'license_plate') {
            ddd['license_plate'] = value.toUpperCase();
            ddd['clicense_plate'] = license_plate.clicense_plate;
        }
        if (name === 'clicense_plate') {
            ddd['clicense_plate'] = value.toUpperCase();
            ddd['license_plate'] = license_plate.license_plate;
        }
        setSaveAsDraft('license_plate', ddd)

        // End SaveAsDraft

        // const pattern = new RegExp(registrationCountry.pattern); // coming from json
        if (licensePlatePattern.test(value.toUpperCase())) {
            setLicensePlateerror(false)
        } else {
            setLicensePlateerror(true)
        }
    }

    // change email
    const emailChange = (e) => {
        const { name, value } = e.target;
        setUserInfo(userInfo => ({ ...userInfo, [name]: value }));
        if (name === 'password') {
            const isValid = passwordPattern.test(value);
            if (value != '')
                setIsPassOneError(!isValid);
            else
                setIsPassOneError(false);
        }
        if (value.slice(0)) {
            setIsValid(false)
            return;
        }
    }

    const backToHome = () => {
        console.log("backToHome addlVigLen",addlVignettes.length);
        setSaveAsDraft('activeTab', 1);
        setSaveAsDraft('showCheckout', false);
        setSaveAsDraft('stepOne', "active show");
        setSaveAsDraft('stepFour', "");
        let lastVig = addlVignettes[0];
        let vigInfo = {
            countryId: lastVig.countryId,
            vehicleId: "",
            durationId: "",    
            countryName: lastVig.countryName,
            durationName: "",
            price: "",
            priceNum: 0,
            flexiCharge: 0,
            licensePlate: "",
            regCountryName: "",
            regCountryCode: "",
            regCountryFlag: "/assets/images/empty-vehicle.png",
            regBackColor: "",
            startDate: startDate, 
            endDate: endDate, 
            vehicleImage: "",
            vehicleName: "",
            countryFilename: lastVig.countryFilename,
            processing: "PaymentOnly",
            isBuyNow: false
        };
        addlVignettes.unshift(vigInfo);
        setSaveAsDraft('addlVignettes', addlVignettes);
        setSaveAsDraft('backToAddNewVignette', true);
        navigate("/home")
    }

    const testPdf = () => {
        createPdf({
            userId: loginUser['_id'],
            orderId: '000117',
            licensePlate: "HGK010",
            vignetteId: '345678',
            eventname: "test pdf"
        }).then(async (ordRes) => {
        }, err => {
            setIsLoading(false);
        })
    }

    // Buy Now
    const buyImmediately = () => {
        let todayDate = new Date();
        setTimeStamp(todayDate.toLocaleString('en-GB', { hour: 'numeric', minute: 'numeric', hour12: true })); // it's Time Stamp
        setTempStartDate(todayDate.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }));
        setStartDate(todayDate);
        //
        // Retrieve 1st Vignette Info from the array and then modidy
        //
        let copyArr = [...addlVignettes];
        const firstElem = copyArr[0];
        if (firstElem != null) {
            firstElem.startDate = todayDate;  // todayDate.toLocaleDateString('en-GB');
            copyArr[0] = firstElem;
            setAddlVignettes(copyArr);
            calcTotalCost();
            calcVignetteCost();    
        }
        setIsBuyNow(true);
        setSaveAsDraft('isBuyNow', true);
        handlerFlexiChange(todayDate);
        setServiceCharge(0);
        setSaveAsDraft('flex_service_charge', 0);
        setSaveAsDraft('start_date', todayDate.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }));
        setSaveAsDraft('timeStamp', todayDate.toLocaleString('en-GB', { hour: 'numeric', minute: 'numeric', hour12: true }));
    }

    function formatDate(date) {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
      
        return `${day}/${month}/${year}`;
    }
  
    // Select Start Date
    function handler(date) {
        if (date) {
            const checkDate = date.getDate()+''+date.getMonth()
            const checkDate1 = minDate.getDate()+''+minDate.getMonth()
            if(checkDate == checkDate1){
                buyImmediately();
                // changeEndDateAsPerDuration(date);
            }
            else{
                setIsBuyNow(false);
                setSaveAsDraft('isBuyNow', false);
                setTempStartDate(date.toLocaleDateString('en-GB', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric'
                }));
                setStartDate(date);
                //
                // Retrieve 1st Vignette Info from the array and then modidy
                //
                let copyArr = [...addlVignettes];
                const firstElem = copyArr[0];
                if (firstElem != null) {
                    firstElem.startDate = date; // date.toLocaleDateString('en-GB');
                    copyArr[0] = firstElem;
                    setAddlVignettes(copyArr);
                    calcTotalCost();
                    calcVignetteCost();            
                }
                // setEndDate(date);
                // changeEndDateAsPerDuration(date);
                setSaveAsDraft('start_date', date.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }));
                handlerFlexiChange(date);
            }
        }
    }

    function changeEndDateAsPerDuration(startDate2) {
        let end_date = new Date(startDate2);
        if (currentDuration === "Weekly") {
            end_date.setDate(startDate2.getDate() + 7);
        }
        if (currentDuration === "Monthly") {
            end_date.setDate(startDate2.getDate() + 30);
        }
        if (currentDuration === "Quarterly") {
            end_date.setDate(startDate2.getDate() + 90);
        }
        if (currentDuration === "Yearly" || currentDuration == 'Annual') {
            end_date.setDate(startDate2.getDate() + 365);
        }
        if (currentDuration == "2-month") {
            end_date.setDate(startDate2.getDate() + 60);
        }
        if (currentDuration == "10-days") {
            end_date.setDate(startDate2.getDate() + 10);
        }
        if (currentDuration == "1-day") {
            end_date.setDate(startDate2.getDate() + 1);
        }
        if (currentDuration == "Weekend") {
            end_date.setDate(startDate2.getDate() + 2);
        }
        setEndDate(end_date);
        //
        // Retrieve 1st Vignette Info from the array and then modidy
        //
        let copyArr = [...addlVignettes];
        const firstElem = copyArr[0];
        if (firstElem != null) {
            firstElem.endDate = end_date; // end_date.toLocaleDateString('en-GB');
            copyArr[0] = firstElem;
            setAddlVignettes(copyArr);
            calcTotalCost();
            calcVignetteCost();    
        }
        setSaveAsDraft('end_date', end_date.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }));
    }

    // Flexi Change get and set
    function handlerFlexiChange(date) {
        // readRecord({
        //     "table": "siteconfig",
        //     "selectFields": {},
        //     "whereCondition": {},
        //     "sortBy": {},
        //     "limit": "1",
        //     "populate": [],
        //     "page": "1"
        // }).then(async (configRes) => {
            let todayDate = new Date();
            let dateNew = new Date(allSettings.flexiTime * 1000);
            const specificTime = dateNew.toLocaleString([], { hour: 'numeric', minute: '2-digit', hour12: false });
            const [specificHour, specificMinute] = specificTime.split(':');

            todayDate.setHours(specificHour);
            todayDate.setMinutes(specificMinute);
            todayDate.setDate(todayDate.getDate() + Number(allSettings.flexiDays));
            setServiceCharge(allSettings.serviceCharge);
            if (date > todayDate) {
                const flexiDate = new Date(date);
                flexiDate.setHours(specificHour);
                flexiDate.setMinutes(specificMinute);
                flexiDate.setDate(flexiDate.getDate() - (Number(allSettings.flexiDays)));
                const formattedDate = flexiDate.toLocaleString('en-GB', { weekday: 'long', month: 'long', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true });
                const formattedTime = flexiDate.toLocaleString('en-GB', { hour: 'numeric', minute: 'numeric', hour12: true });
                setTimeStamp(formattedTime);
                setBenefitsBy(formattedDate)
                setTepmFlexiChange(allSettings.flexiCharge);
            } else {
                setBenefitsBy("");
                setFlexiChange(0);
            }
    }

    // flexi charge check box
    const flexichargefun = (e) => {
                //
        // Retrieve 1st Vignette Info from the array and then modidy
        //
        let copyArr = [...addlVignettes];
        const firstElem = copyArr[0];
        if (e.target.checked) {
            setFlexiChange(allSettings.flexiCharge);  //flexiObj.flex_service_charge);
            setSaveAsDraft('flex_service_charge', allSettings.flexiCharge);  // flexiObj.flex_service_charge);
            // setServiceCharge(0);
            if (firstElem != null) {
                firstElem.flexiCharge = allSettings.flexiCharge;
                copyArr[0] = firstElem;
                setAddlVignettes(copyArr);
                calcTotalCost();
                calcVignetteCost();        
            }    
        } else {
            setFlexiChange(0);
            if (firstElem != null) {
                firstElem.flexiCharge = 0;
                copyArr[0] = firstElem;
                setAddlVignettes(copyArr);
                calcTotalCost();
                calcVignetteCost();        
            }    
            setSaveAsDraft('flex_service_charge', 0);
            setServiceCharge(allSettings.serviceCharge);  //flexiObj.service_charge);
        }
    }

    // Step change Next button
    async function changeStep(page) {
        {/*
        if (page === 2) {
            setStepOne("");
            if (!startDate || !selectedCountry._id || !selectedVehicle._id || !selectedDuration._id) {
                warningToast("Please fill the all required fields");
                setStepOne("active show");
                setStepOneError(true);
                return;
            }
            setStepOneError(false);
            setStepTwo("active show");
            setActiveTab(page); // for previous step active
            setSaveAsDraft('activeTab', page);
        }
        */}
        if (page === 3) {
            setStepOne("");
            if (!startDate || !selectedCountry._id || !selectedVehicle._id || !selectedDuration._id) {
                warningToast("Please fill the all required fields");
                setStepOne("active show");
                setStepOneError(true);
                return;
            }
            setStepOneError(false);
            setStepTwo("");
            if (!license_plate.clicense_plate || !license_plate.license_plate) {
                // warningToast("Please fill the all required fields");
                setStepTwo("active show");
                setStepTwoError(true);
                return;
            }
            if (license_plate.clicense_plate !== license_plate.license_plate) {
                setStepTwoError(true);
                setStepTwo("active show");
                return;
            }
            if (licensePlateerror) {
                setStepTwo("active show");
                setLicensePlateerror(true)
                return;
            }

            setStepTwoError(false);
            setStepThree("active show");
            setActiveTab(page); // for previous step active
            setSaveAsDraft('activeTab', page);
        }
        if (page === 4) {

            if ((!userInfo.guest_email || !acceptTerms) && (!userInfo.guest_email && (!userInfo.email || !userInfo.confirm_email || !userInfo.password || !acceptTerms))) {
                warningToast("Please fill the all required fields");
                setStepThree("active show");
                setStepThreeError(true);
                return;
            }
            if (userInfo.email !== userInfo.confirm_email) {
                // warningToast("Email and Confirm email not matching");
                setStepThree("active show");
                setStepThreeError(true);
                return;
            }
            const pattern1 = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if ((userInfo.email && !pattern1.test(userInfo.email)) || (userInfo.guest_email && !pattern1.test(userInfo.guest_email))) {
                setStepThree("active show");
                setIsValid(true)
                return;
            }
            if (isPassOneError) {
                if (userInfo.password != '') {
                    setStepThree("active show");
                    setStepThreeError(true);
                    return;
                }
                else {
                    setIsPassOneError(false);
                }
            }
            if (isLoggedIn && !showOtp) {
                setStepThree("");
                setStepFour("active show");
                if (!flexiChange) {
                    setServiceCharge(allSettings.serviceCharge);
                }
                setShowCheckout(true);
                setSaveAsDraft('activeTab', page);
                vehicleAddEditSubmit(loginUser);
            } else {
                readRecord({
                    "table": "customers",
                    "selectFields": {},
                    "whereCondition": {
                        "email": !userInfo.email ? userInfo.guest_email.trim():userInfo.email.trim(),
                        "deleted": null
                    },
                    "sortBy": {},
                    "populate": [],
                    "limit": "1",
                    "page": "1"
                }).then(async (res) => {
                    setStepThree("");
                    if (res.status && res.data.length) {
                        if ((!userInfo.email && userInfo.guest_email) || (res.data[0].password === userInfo.password)) {
                            if (res.data[0].emailCode) {
                                if (showOtp) {
                                    if (!userInfo.emailCode) {
                                        errorToast("Please enter valid Email Code");
                                        setShowCheckout(false);
                                        setStepThree("active show");    
                                    }
                                    else        
                                    if (userInfo.emailCode !== res.data[0].emailCode) {
                                        errorToast("You have entered an Invalid Email Code");
                                        setShowCheckout(false);
                                        setStepThree("active show");    
                                    }
                                    else {
                                        successToast("Email Verification successfull");
                                        setLoginUser(res.data[0]);
                                        verifyEmailCode({
                                            "email": !userInfo.email ? userInfo.guest_email.trim():userInfo.email.trim(),
                                            "emailcode": res.data[0].emailCode
                                        }).then(async (res) => { }, err => { })
                                        setShowCheckout(true);
                                        vehicleAddEditSubmit(res.data[0]);
                                        setStepFour("active show");
                                        if (!flexiChange) {
                                            setServiceCharge(allSettings.serviceCharge);
                                        }        
                                        setActiveTab(page); // for previous step active
                                        setSaveAsDraft('activeTab', page);
                                    }
                                }
                                else {
                                    // 27-06 as per Br Nur, disable it for now - Start
                                    // setShowOtp(true); 
                                    // setOtpVerified(false);
                                    // errorToast("Please enter valid Email Code");                                    
                                    // setShowCheckout(false);
                                    // setStepThree("active show");
                                    // 27-06 as per Br Nur, disable it for now - End
                                    setLoginUser(res.data[0]);
                                    setShowCheckout(true);
                                    vehicleAddEditSubmit(res.data[0]);
                                    setStepFour("active show");
                                    if (!flexiChange) {
                                        setServiceCharge(allSettings.serviceCharge);
                                    }        
                                    setActiveTab(page); // for previous step active
                                    setSaveAsDraft('activeTab', page);
                                    if (!userInfo.email && userInfo.guest_email)
                                        setSaveAsDraft('isGuestLogin', true);
                                    else
                                        setSaveAsDraft('isGuestLogin', false);
                                }
                            }
                            else {
                                setLoginUser(res.data[0]);
                                successToast("Email address already registered");
                                setShowCheckout(true);
                                vehicleAddEditSubmit(res.data[0]);
                                setStepFour("active show");
                                if (!flexiChange) {
                                    setServiceCharge(allSettings.serviceCharge);
                                }
                                setActiveTab(page); // for previous step active
                                setSaveAsDraft('activeTab', page);
                                if (!userInfo.email && userInfo.guest_email)
                                    setSaveAsDraft('isGuestLogin', true);
                                else
                                    setSaveAsDraft('isGuestLogin', false);
                            }
                        } else {
                            setShowCheckout(false);
                            setStepThree("active show");
                            errorToast("Your password is not matching with email address");
                        }
                    } else {
                        // setShowOtp(true);  27-06 as per Br Nur, disable it for now
                        setOtpVerified(false);
                        createRecord({
                            "record": {
                                "first_name": "",
                                "last_name": "",
                                "email": !userInfo.email?userInfo.guest_email.trim():userInfo.email.trim(),
                                "password": userInfo.password,
                                "company_name": "",
                                "title": "",
                                "address": "",
                                "postcode": "",
                                "city": "",
                                "vat_id": "",
                                "status": "ACTV",
                                "user_type": "customer",
                                "create_user": null,                                    
                                "deleted": null,
                                "deletedby": null,
                                "emailCode": "TODO*&#*&#&#^&^&#TODO",
                            },
                            "table": "customers",
                            "uniqueFields": {},
                            "uniqueFieldErrMsg": "Record alrady exist."
                        }).then(async (res) => {
                            if (res.status) {
                                successToast("Email address registered successfully");
                                // 27-06 as per Br Nur, disable it for now
                                // successToast("Please check your Email for Email Verification Code");
                                setLoginUser(res.data);
                                vehicleAddEditSubmit(res.data);
                                if (!flexiChange) {
                                    setServiceCharge(allSettings.serviceCharge);
                                }
                                setActiveTab(page); // for previous step active
                                setSaveAsDraft('activeTab', page);
                                // 27-06 as per Br Nur, disable it for now - Start
                                // setShowCheckout(false);
                                // setStepThree("active show");
                                setShowCheckout(true);
                                setStepFour("active show");
                                // 27-06 as per Br Nur, disable it for now - End
                                if (!userInfo.email && userInfo.guest_email)
                                    setSaveAsDraft('isGuestLogin', true);
                                else
                                    setSaveAsDraft('isGuestLogin', false);
                            } else {
                                setShowCheckout(false);
                                setStepThree("active show");
                            }
                        })
                    }
                }, err => { setIsLoading(false) })
            }
        }

        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    function onRemoveVignette(index) {
        if (addlVignettes != null) {
            if (addlVignettes != null && addlVignettes.length >= index) {
                const updatedVignettes = [...addlVignettes]; 
                updatedVignettes.splice(index - 1, 1);
                setAddlVignettes(updatedVignettes);       
                if (updatedVignettes.length <= 0) {
                    setServiceCharge(0);
                }
                calcTotalCost();
                calcVignetteCost();                 
            }
        }
    };        

    function calcVignetteCost() {
        var totCost = 0; // selectedDuration.price * exchangeRates.price;
        if (addlVignettes != null) {
            var acc = 0;
            if (addlVignettes != null && addlVignettes.length > 0) {
                acc = addlVignettes.reduce((acc, addlItem) => acc + (addlItem.priceNum * exchangeRates.price), 0);
                totCost += acc;
            }
        }
        setVignetteCost(totCost);
        return (totCost).toFixed(2);
    }

    function calcTotalCost() {
        var totCost = 0; // selectedDuration.price * exchangeRates.price;
        // totCost += (flexiChange * exchangeRates.price);
        totCost += (serviceCharge);
        if (addlVignettes != null) {
            var acc = 0, acc2 = 0;
            if (addlVignettes != null && addlVignettes.length > 0) {
                acc = addlVignettes.reduce((acc, addlItem) => acc + (addlItem.priceNum * exchangeRates.price), 0);
                totCost += acc;
                acc2 = addlVignettes.reduce((acc2, addlItem) => acc2 + (addlItem.flexiCharge * exchangeRates.price), 0);
                totCost += acc2;
            }
        }
        setTotalCost(totCost);
        return (totCost).toFixed(2);
    }

    function getTotalFlexiCharges() {
        var totCost = 0;  // flexiChange * exchangeRates.price;
        if (addlVignettes != null) {
            var acc = 0;
            if (addlVignettes != null && addlVignettes.length > 0) {
                acc = addlVignettes.reduce((acc, addlItem) => acc + (addlItem.flexiCharge * exchangeRates.price), 0);
                totCost += acc;
            }
        }
        return (totCost).toFixed(2);
    }
{/*
    function getTotalServiceCharges() {
        var totCost = (serviceCharge * exchangeRates.price);
        return (totCost).toFixed(2);
    }
*/}

    // Step change Back button
    function backStep(page) {
        setStepOne("");
        setStepTwo("");
        setStepThree("");
        setStepFour("");
        setActiveTab(page);
        setSaveAsDraft('activeTab', page);
        if (page === 1) {
            setStepOne("active show");
        }
        if (page === 2) {
            setStepTwo("active show");
        }
        if (page === 3) {
            setServiceCharge(0);
            setShowCheckout(false);
            setStepThree("active show");
        }
    }

    // Order Place Stripe
    const processAfterPayment = (token, paymentType, partnersSaleRes, secretInfo) => {
        console.log(">>>> processAfterPayment");
        setIsLoading(true);
        // 
        // Obtain new Order Number
        //
        let orderPlacedId = -1;
        let lastSiteConfigId = -1;
        let newOrderNumber = null;
        let sendInfo = {};
        let vignetteArr = [];
        if (secretInfo) {
            sendInfo.password = secretInfo.password==''?'abaracadabara':secretInfo.password;
            vignetteArr = secretInfo.vignette.split(",");
        }
        readRecord({
           "table": "siteconfig",
           "selectFields": {},
           "whereCondition": sendInfo,
           "sortBy": {},
           "limit": "1",
           "populate": [],
           "page": "1"
        }).then(async (configRes) => {
            console.log("configRes", configRes);
            if (!configRes['status']) {
                console.log("Wrong password info",configRes.message);    
                return;
            }
            newOrderNumber = String(Number(configRes['data'][0].last_order_on) + 1).padStart(6, '0');
            console.log("New OrderNumber obtained",newOrderNumber);
            lastSiteConfigId = configRes['data'][0]._id;
            //
            // Prepare data and create new record on the Order table
            //
            let date = new Date(startDate);
            let end_date = new Date(endDate);
            end_date = end_date.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' });
            if (addlVignettes != null && addlVignettes.length > 0) {
                for (let i in addlVignettes) {                 
                    let vigInfo = addlVignettes[i];
                    let useVigId = null;
                    if (vignetteArr && (i+1)>=vignetteArr.length) {
                        useVigId = vignetteArr[i];
                        if (useVigId == '' || useVigId == 'null') {
                            useVigId = null;
                        }
                    }
                    //
                    // Insert new record on Orders table
                    //
                    let newRegCountry = {
                        countryName: vigInfo.regCountryName,
                        countryCode: vigInfo.regCountryCode,
                        countryFlag: vigInfo.regCountryFlag,
                        countryBackColor: vigInfo.regBackColor
                    };
                    const Body = {
                        "record": {
                            country: vigInfo.countryId,
                            vehicle_type: vigInfo.vehicleId, 
                            duration: vigInfo.durationId,
                            start_date: vigInfo.startDate,
                            time_stamp: timeStamp,
                            end_date: vigInfo.endDate, 
                            isBuyNow: vigInfo.isBuyNow, 
                            is_flex_date: vigInfo.flexiCharge > 0 ? 'Yes' : 'No',
                            license_plate: vigInfo.licensePlate,
                            order_number: newOrderNumber,
                            service_charge: serviceCharge,
                            ex_service_charge: parseFloat((serviceCharge * exchangeRates.price).toFixed(2)),
                            flex_service_charge: vigInfo.flexiCharge, 
                            ex_flex_service_charge: parseFloat((vigInfo.flexiCharge * exchangeRates.price).toFixed(2)),
                            sub_amount: vigInfo.priceNum, 
                            ex_sub_amount: vigInfo.priceNum, 
                            order_amount: vigInfo.priceNum + vigInfo.flexiCharge + serviceCharge, 
                            ex_order_amount: vigInfo.priceNum + vigInfo.flexiCharge + serviceCharge, 
                            email_address: !userInfo.email?userInfo.guest_email.trim():userInfo.email.trim(),
                            customer: loginUser['_id'] || null,
                            exchange_rate: exchangeRates.price,
                            def_currency: defaultCurrency.absolute_value,
                            paymentType: paymentType,
                            ex_currency: exchangeRates.absolute_value,
                            def_currency_code: defaultCurrency.code,
                            ex_currency_code: exchangeRates.code,
                            order_paymentDtl: JSON.stringify(token),
                            vehicle_country_of_registration: JSON.stringify(newRegCountry),
                            order_status: vigInfo.processing == 'PaymentOnly' ? (useVigId!=null?'Approved':'Pending') : null,
                            vignette_id: useVigId,
                            status: "ACTV",
                            create_user: loginUser['_id'] || null,
                            deleted: null,
                            deletedby: null
                        },
                        "table": "orders",
                        "uniqueFieldsContionsOperator": "and",
                        "uniqueFields": {},
                        "uniqueFieldErrMsg": "Record alrady exist."
                    }
                    createRecord(Body).then(async (res) => {
                    if (res.status) {
                        orderPlacedId = res.data._id;
                        //
                        // Update Last Order Id
                        //
                        updateRecord({
                            "record": {
                                last_order_on: newOrderNumber
                            },
                            "_id": lastSiteConfigId,
                            "table": "siteconfig",
                            "uniqueFields": {},
                            "uniqueFieldErrMsg": "Record alrady exist."
                        }).then(async (res) => { }, err => { })
                        //
                        // create transactions
                        //
                        createRecord({
                            "record": {
                                transaction_type: "Order Received",
                                transaction_status: "approved",
                                invoice_date: new Date().toISOString(),
                                order_paymentDtl: JSON.stringify(token),
                                payment_status: "success",
                                currency: "EUR",
                                exchange_rate: 1,
                                total_charges: vigInfo.priceNum, // vignetteCost,
                                ex_total_charges: vigInfo.priceNum, // vignetteCost,
                                total_amount: vigInfo.priceNum + vigInfo.flexiCharge + serviceCharge, // totalCost,
                                ex_amount: vigInfo.priceNum + vigInfo.flexiCharge + serviceCharge, // totalCost, 
                                order_id: res['data']['_id'],
                                customer_id: loginUser['_id'] || null,
                                status: "ACTV",
                                create_user: loginUser['_id'] || null,
                                deleted: null,
                                deletedby: null
                            },
                            "table": "transactions",
                            "uniqueFields": {},
                            "uniqueFieldErrMsg": "Record alrady exist."
                        }).then(async (transRes) => {
                            if (!transRes.status) {
                                errorToast(transRes.message);
                            }
                        }, err => {
                            setIsLoading(false);
                        })
                        //
                        // As we are done with saving the data to Database, now lets generate Vignette (if applicable)
                        //
                        if (vigInfo.processing == 'PaymentAndDigitoll') {
                            console.log("sending digitoll order for selected country",selectedCountry);
                            //
                            // Get Digitoll Token
                            //
                            digitollPost({
                                "url": "user/authenticate",
                                "delvyBody": {}
                            }).then(async (tokenRes) => {
                            if (tokenRes.status) {
                                if (partnersSaleRes != null) {  // don't send email when this is null, it may have failed
                                    //
                                    // Send email to customer for "Digitoll order placed"
                                    //
                                    sendEmail({
                                        userId: loginUser['_id'],
                                        orderId: res.data._id,
                                        countryName: vigInfo.countryName, 
                                        countryCode: vigInfo.countryId, 
                                        vignetteId: partnersSaleRes!=null?partnersSaleRes.data.saleRows[0].kapschProperties.id:null,
                                        eventname: "Digitoll Order Placed"
                                    }).then(async (ordRes) => {
                                        console.log(">>>> sendEmail-A success");
                                    }, err => {
                                        console.log(">>>> sendEmail-Error1");
                                        setIsLoading(false);
                                    })
                                }
                                const OrdUpdate = (orderItem) => {
                                    updateRecord({
                                        "record": orderItem,
                                        "_id": res['data']['_id'],
                                        "table": "orders",
                                        "uniqueFields": {},
                                        "uniqueFieldErrMsg": "Record alrady exist."
                                    }).then(async (ordersUpdtres) => {
                                        // navigate("/thankyou");
                                    }, err => {
                                        setIsLoading(false);
                                    })
                                }
                                if (vigInfo.isBuyNow && partnersSaleRes != null) { // If Buy Now Then call now
                                    digitollPost({
                                        "url": "partners/sale/activate/vignette",
                                        "token": tokenRes.data.token,
                                        "delvyBody": {
                                            "posId": "123",
                                            "vignetteId": partnersSaleRes!=null?partnersSaleRes.data.saleRows[0].kapschProperties.id:null
                                        }
                                    }).then(async (activateRes) => {
                                        if (activateRes.status) {
                                            sendEmail({
                                                userId: loginUser['_id'],
                                                orderId: res.data._id,
                                                countryName: vigInfo.countryName, // selectedCountry.value,
                                                countryCode: vigInfo.countryId, // selectedCountry._id,                    
                                                vignetteId: partnersSaleRes!=null?partnersSaleRes.data.saleRows[0].kapschProperties.id:null,
                                                templateVar1: vigInfo.regCountryName, // Registration Country
                                                eventname: "Vignette Activated"
                                            }).then(async (ordRes) => {
                                                console.log(">>>> sendEmail-C success");
                                            }, err => {
                                                console.log(">>>> sendEmail-Error2");
                                                setIsLoading(false);
                                            })
                                            const rr = await OrdUpdate({
                                                digi_partners_sale: partnersSaleRes!=null?JSON.stringify(partnersSaleRes.data):null,
                                                activationResponse: JSON.stringify(activateRes.data),
                                                isDigiVignetteActive: true,
                                            });
                                        } 
                                        else {
                                            setIsLoading(false);
                                            saveErrorLogs({
                                                error: activateRes?.message,
                                                function_name: "partners/sale/activate/vignette",
                                                type: "Digitoll vignette activate",
                                            })
                                        }
                                    }, err => {
                                        setIsLoading(false);
                                        console.log(">>>> someError-Error9");
                                    })
                                } // isBuyNow == true
                                else { // isBuyNow == false then activation will be done from the cron job
                                    const rr = await OrdUpdate({
                                        digi_partners_sale: partnersSaleRes!=null?JSON.stringify(partnersSaleRes.data):null,
                                    });
                                }
                            } // digitoll authenticate success
                            else {
                                setIsLoading(false);
                            }
                            }, err => {
                            setIsLoading(false);
                            });
                            sendEmail({
                                userId: loginUser['_id'],
                                orderId: orderPlacedId,
                                countryName: vigInfo.countryName, 
                                countryCode: vigInfo.countryId, 
                                eventname: "Order Placed"
                            }).then(async (ordRes) => {
                            }, err => {
                                setIsLoading(false);
                            });            
                        }  // end of if block for countries with PaymentAndDigitoll (eg. Bulgaria)
                        else {
                            console.log("Processinging for countries other than PaymentAndDigitoll eg. PaymentOnly (Austria etc.)");
                            sendEmail({
                                userId: loginUser['_id'],
                                orderId: orderPlacedId,
                                countryName: vigInfo.countryName, 
                                countryCode: vigInfo.countryId, 
                                eventname: "PaymentOnly Order Placed"
                            }).then(async (ordRes) => {
                            });
                        }
                        //
                        // Send Email for "Order Placed"
                        //
                        successToast("Order Placed successfully");
                        const queryString = new URLSearchParams({ ordersId: newOrderNumber }).toString();
                        navigate(`/thankyou?${queryString}`);
                        setIsLoading(false);                
                    } else {
                        errorToast(res.message);
                        setIsLoading(false);
                    }
                    }, err => {
                    })
                } // for loop
            }  // if vignettes exists
        }, err => {
            console.log("Error obtaining new OrderNumber",err);
        });
    };

    const vehicleAddEditSubmit = (user) => {
        // add
        let vigInfo = addlVignettes[0];
        let newRegCountry = {
            countryName: vigInfo.regCountryName,
            countryCode: vigInfo.regCountryCode,
            countryFlag: vigInfo.regCountryFlag,
            countryBackColor: vigInfo.regBackColor
        };
        const Body = {
            "record": {
                vehicle_number: license_plate.license_plate.trim(),
                vehicle_country_of_registration: JSON.stringify(newRegCountry),
                country: vigInfo.countryName,
                vehicle_type: customerVehicleType,
                customerId: user['_id'],
                create_user: user['_id'],
                status: "ACTV",
                deleted: null,
                deletedby: null,
            },
            "table": "customer_vehicles",
            "uniqueFieldsContionsOperator": "and",
            "uniqueFields": {
                "vehicle_number": license_plate.license_plate.trim(),
                "country": vigInfo.countryName,
                "customerId": user['_id'],
                "deleted": null,
            },
            "uniqueFieldErrMsg": "Vehicle number alrady exist."
        }

        createRecord(Body).then(async (res) => {
            setIsLoading(false)
            if (res.status) {
                let vigInfo = addlVignettes[0];
                let newRegCountry = {
                    countryName: vigInfo.regCountryName,
                    countryCode: vigInfo.regCountryCode,
                    countryFlag: vigInfo.regCountryFlag,
                    countryBackColor: vigInfo.regBackColor
                };        
                getMyVehicles(newRegCountry);
            } else {
                // errorToast(res.message);
            }
        }, err => { setIsLoading(false) })
    }

    const saveErrorLogs = (data) => {
        // add
        const Body = {
            "record": {
                site: "Vignette frontend",
                error: JSON.stringify(data.error || {}),
                function_name: data.function_name,
                type: data.type,
                create_user: loginUser['_id'] || null,
                status: "ACTV",
                deleted: null,
                deletedby: null,
            },
            "table": "error_logs",
            "uniqueFields": {},
            "uniqueFieldErrMsg": "Vehicle number alrady exist."
        }

        createRecord(Body).then(async (res) => {
            setIsLoading(false)
            if (res.status) {
            } else {
                // errorToast(res.message);
            }
        }, err => { setIsLoading(false) })
    }

    const filterWeekdays = (date) => {
        if (selectedDuration.value === "Weekend") {
            const day = date.getDay();
            return day === 0 || day === 6; // Enable only Sundays (0) and Saturdays (6)
        } else {
            return true; // Enable only Sundays (0) and Saturdays (6)
        }
    };

    return (
        <>
            <div className="page-content p-t80">
                <div className="section-full container">
                    <div className="p-a20">
                        <div className="wt-listing-full-width">
                            <div className="panel panel-default">
                                {/* <div className="panel-heading wt-panel-heading p-2">
                                        <h4 className="panel-tittle m-0"><i className="fa fa-car"></i>Find Vehicle</h4>
                                    </div> */}
                                <div className="panel-body wt-panel-body p-a20 mobile-p-remove m-b30 ">
                                    <div role="tabpanel">
                                        <div className="list-group list-group-horizontal" id="myList" role="tablist">
                                            <Link className={"list-group-item list-group-item-action disabled text-center " + `${stepOne}`} data-bs-toggle="list" to="#home" role="tab">
                                                {isMobileView ?
                                                    <button className={(activeTab >= 1 ? "step-btn mobile-step-btn active-tab" : 'step-btn mobile-step-btn')}>{(activeTab >= 1 ? <i className="fa-solid fa-check  me-1"></i> : "")}1</button>
                                                    : <button className={(activeTab >= 1 ? "step-btn active-tab" : 'step-btn')}>{(activeTab <= 1 ? "" : <i className="fa-solid fa-check  me-1"></i>)}{t('Vehicle details')}</button>}
                                                <i className="feather-navigation-2 step-arrow"></i>
                                                <span className={(activeTab >= 1 ? "divider-1 active-tab" : 'divider-1')}></span>
                                            </Link>
                                            {/*
                                            <Link className={"list-group-item list-group-item-action disabled text-center " + `${stepTwo}`} data-bs-toggle="list" to="#profile" role="tab">
                                                {isMobileView ?
                                                    <button className={(activeTab >= 2 ? "step-btn mobile-step-btn active-tab" : 'step-btn mobile-step-btn')}>{(activeTab >= 2 ? <i className="fa-solid fa-check  me-1"></i> : "")}2</button>
                                                    : <button className={(activeTab >= 2 ? "step-btn active-tab" : 'step-btn')}>{(activeTab <= 2 ? "" : <i className="fa-solid fa-check me-1"></i>)}{t('Vignette details')}</button>}
                                                <i className="feather-navigation-2 step-arrow"></i>
                                                <span className={(activeTab >= 2 ? "divider-2 active-tab" : 'divider-2')}></span>
                                            </Link>
                                                */}
                                            <Link className={"list-group-item list-group-item-action disabled text-center " + `${stepThree}`} data-bs-toggle="list" to="#messages" role="tab">
                                                {isMobileView ?
                                                    <button className={(activeTab >= 3 ? "step-btn mobile-step-btn active-tab" : 'step-btn mobile-step-btn')}>{(activeTab >= 3 ? <i className="fa-solid fa-check  me-1"></i> : "")}3</button>
                                                    : <button className={(activeTab >= 3 ? "step-btn active-tab" : 'step-btn')}>{(activeTab <= 3 ? "" : <i className="fa-solid fa-check me-1"></i>)}{t('Personal details')}</button>}
                                                <i className="feather-navigation-2 step-arrow"></i>
                                                <span className={(activeTab >= 3 ? "divider-3 active-tab" : 'divider-3')}></span>
                                            </Link>
                                            <Link className={"list-group-item list-group-item-action disabled text-center " + `${stepFour}`} data-bs-toggle="list" to="#messages" role="tab">
                                                {isMobileView ?
                                                    <button className={(activeTab >= 4 ? "step-btn mobile-step-btn active-tab" : 'step-btn mobile-step-btn')}>{(activeTab >= 4 ? <i className="fa-solid fa-check  me-1"></i> : "")}4</button>
                                                    : <button className={(activeTab >= 4 ? "step-btn active-tab" : 'step-btn')}>{(activeTab <= 4 ? "" : <i className="fa-solid fa-check me-1"></i>)}{t('Payment')}</button>}
                                                <i className="feather-navigation-2 step-arrow"></i>
                                                <span className={(activeTab >= 4 ? "divider-4 active-tab" : 'divider-4')}></span>
                                            </Link>
                                        </div>
                                        <div className="row  m-b30">
                                            <div className="col-lg-8 col-md-12">
                                                <div className="tab-content mt-4">
                                                    <div className={"tab-pane " + `${stepOne}`} id="home" role="tabpanel">


                                                {/* First Row */}
                                                <div className={isMobileView ? "" : "d-flex"} style={{ width: '65%', marginTop:"20px" }} >
                                                    {/* First Col */}
                                                    <div>
                                                        <h4>{t('Select your Vehicle Country')}</h4>
                                                        <small className="form-label">{t('Country where your Vehicle is registered')}</small>
                                                        <div className="col-md-4 mb-2" style={{ width: '100%' }}>
                                                            <Select className="select-border"
                                                                options={licensePlateData}
                                                                value={registrationCountry}
                                                                onChange={changeRegCountry}
                                                                styles={colourStyles}
                                                                placeholder={t("Select Country")}
                                                                formatOptionLabel={(option) => (
                                                                    <div>
                                                                        <img
                                                                            src={`/assets/images/flags/${option.countryFlag}`}
                                                                            alt={option.flag.alt}
                                                                            style={{ width: '20px', marginRight: '5px'}}
                                                                        />
                                                                        {option.countryCode} {option.label}
                                                                    </div>
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                    {/* End Col */}
                                                    {myAllVehicle.length ?
                                                    <div style={{marginLeft:isMobileView ?"0%":"10%", width: isMobileView ?'100%':'40%'}}>
                                                        <h4>&nbsp;</h4>
                                                        <small className="form-label">{t('My Vehicles')}</small>
                                                        <div className="col-md-4 mb-2" style={{ width: '100%' }}>
                                                            <Select className="select-border"
                                                                // isDisabled={true}
                                                                options={myAllVehicle} // set list of the data
                                                                value={mySelectedVehicle}
                                                                onChange={changeMyVehicle}
                                                                styles={colourStyles}
                                                                placeholder={t("Select Vehicle")}
                                                            />
                                                        </div>
                                                    </div> : ""}
                                                </div>
                                                {/* End First Row */}
                                                {/* Second Row */}
                                                <div className={isMobileView ? "" : "d-flex"} style={{ width: '65%', marginTop:"20px" }} >
                                                {/* First Col */}
                                                {(registrationCountry && registrationCountry?.flag) && 
                                                    <div className="form-group col-md-6 mb-3" style={{ marginRight: isMobileView ? "0%" : "10%" }}>
                                                        <label className="form-label">{t('Enter license plate')}</label>
                                                        <div className="ls-inputicon-box">

                                                            <input type="text" name="license_plate" value={license_plate.license_plate} onChange={changeLicense} className="form-control ps-5" placeholder={registrationCountry?.placeholder} />
                                                            {registrationCountry?.flag?.img &&
                                                                <div className="license-plate-flag" style={{ backgroundColor: registrationCountry.flag.color }}>
                                                                    <img src={imageURL + registrationCountry.flag.img} style={{ maxWidth: '22px' }} className="" />
                                                                    <span className="country-name">{registrationCountry.code}</span>
                                                                </div>
                                                            }
                                                        </div>
                                                        {(isStepTwoError && !license_plate.license_plate) ? <p className="text-danger">License plate is mandatory</p> : ''}
                                                    </div>
                                                    }
                                                {(registrationCountry && registrationCountry?.flag) && 
                                                    <div className="form-group col-md-6 mb-3">
                                                        <label className="form-label">{t('Confirm license plate')}</label>
                                                        <div className="ls-inputicon-box">
                                                            {registrationCountry?.flag?.img &&
                                                                <div className="license-plate-flag" style={{ backgroundColor: registrationCountry.flag.color }}>
                                                                    <img src={imageURL + registrationCountry.flag.img} style={{ maxWidth: '22px' }} className="" />
                                                                    <span className="country-name">{registrationCountry.code}</span>
                                                                </div>
                                                            }
                                                        </div>
                                                        <input type="text" name="clicense_plate" value={license_plate.clicense_plate} onChange={changeLicense} className="form-control ps-5" placeholder={registrationCountry?.placeholder} />
                                                        {(isStepTwoError && !license_plate.clicense_plate) ? <p className="mb-0 text-danger">Confirm license plate is mandatory</p> : ''}
                                                        {(isStepTwoError && ((license_plate.clicense_plate !== license_plate.license_plate) && license_plate.clicense_plate)) ? <p className="text-danger mb-0">License and Confirm License not matching</p> : ''}
                                                        {(licensePlateerror) ? <p className="text-danger mt-1">{t('Invalid License Plate Number, please remove if any special characters.')}</p> : ""}
                                                    </div>
                                                    }
                                                {/* Second Col */}
                                                </div>
                                                {/* End Second Row */}

                                                        <div className="row" style={{width:"100%"}}>
                                                            <div className="col-md-12">
                                                                <h4 className="mt-3">{t('Select vehicle type')}</h4>
                                                                {/*
                                                                <button onClick={() => testPdf()}>
                                                                    Test PDF
                                                                </button>
                                                                */}
                                                                <div className="form-group">
                                                                    <div className="twm-tabs-style-2">
                                                                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                                                                            {isLoading && <div style={{ display: 'flex' }}>
                                                                                <SkeletonTheme >
                                                                                    <Skeleton count={1} className="me-3" height={98} width={150} />
                                                                                    <Skeleton count={1} height={98} width={150} />
                                                                                </SkeletonTheme>
                                                                            </div>}
                                                                            {!isLoading && vehicleCatOptions.map((item, index) => {
                                                                                return (
                                                                                    <li key={item.value} className="nav-item" role="presentation">
                                                                                        <button onClick={() => changeVehicleClick(index)} className={"nav-link check-btn " + (`${selectedVehicle._id}` === item._id ? "active" : "")} data-bs-toggle="tab" data-bs-target="#sign-Employer" type="button">
                                                                                            {/* <i className={item.img} style={{ fontSize: "2rem" }}></i> */}
                                                                                            <img src={`${imageURL}${item.filename}`} style={{ width: "45px" }} />
                                                                                            <p className='mb-0'>{t(item.value)}</p>
                                                                                            <div className='mb-0 weight-box'>
                                                                                                <span style={{ color: '#4f4f59' }}>{t('WEIGHT')} : </span>
                                                                                                <span style={{ color: '#ff007a' }}>{t(item.absolute_value)}</span>
                                                                                            </div>
                                                                                        </button>
                                                                                    </li>
                                                                                )
                                                                            })}
                                                                        </ul>
                                                                        {(!vehicleCatOptions.length && !isLoading) ? <small className="text-muted">Vehicles not Available.</small> : ''}
                                                                        {(isStepOneError && !selectedVehicle._id) ? <p className="text-danger mb-0">Please select Vehicle type</p> : ''}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {selectedVehicle._id ? (
                                                                <div className="col-md-12">
                                                                    <h4>{t('Select duration')}</h4>
                                                                    <div className="form-group">
                                                                        <div className="twm-tabs-style-2">
                                                                            <ul className="nav nav-tabs" id="durationTab" role="tablist">
                                                                                {isLoading1 && <div>
                                                                                    <SkeletonTheme>
                                                                                        <div className={isMobileSkeleton ? "grid-containerd" : "grid-container"}>
                                                                                            <div className="grid-item"><Skeleton count={1} height={80} /></div>
                                                                                            <div className="grid-item"><Skeleton count={1} height={80} /></div>
                                                                                            <div className="grid-item"><Skeleton count={1} height={80} /></div>
                                                                                            <div className="grid-item"><Skeleton count={1} height={80} /></div>
                                                                                            <div className="grid-item"><Skeleton count={1} height={80} /></div>
                                                                                        </div>
                                                                                    </SkeletonTheme>
                                                                                </div>}
                                                                                {!isLoading1 && durationOptions.map((item, index) => (
                                                                                    <li className="nav-item" role="presentation" key={item.code}>
                                                                                        <button onClick={() => changeDuration(index)} className={"nav-link check-btn " + (`${selectedDuration._id}` === item._id ? "active" : "")} data-bs-toggle="tab" data-bs-target="#sign-candidate" type="button">
                                                                                            <div>{t(item.value)}</div>
                                                                                            <small>
                                                                                                {exchangeRates.absolute_value} {(item.price * exchangeRates.price).toFixed(2)}
                                                                                            </small>
                                                                                        </button>
                                                                                    </li>
                                                                                ))}
                                                                            </ul>
                                                                            {(!durationOptions.length && vehicleCatOptions.length && !isLoading1) ? <small className="text-muted">Please select first Vehicle type</small> : ''}
                                                                            {(!durationOptions.length && !vehicleCatOptions.length && !isLoading1) ? <small className="text-muted">Vehicles not Available.</small> : ''}
                                                                            {(isStepOneError && !selectedDuration._id) ? <p className="text-danger mb-0">Please select Duration</p> : ''}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ) : ''}

                                                            {selectedDuration._id ? (
                                                                <div className="col-md-8">
                                                                    {/*<h4>{t('Select Start Date (End Date is calculated)')} 
                                                                    </h4> */}
                                                                    <div className="form-group">
                                                                        <div className="date-picker-container">
                                                                        <div className="ls-inputicon-box">
                                                                            <h4>{t('Select Start Date')}</h4>
                                                                            {/*
                                                                        <DateRangePicker
                                                                            ranges={[selectionRange]}
                                                                            onChange={handleSelect}
                                                                            showSelectionPreview="false"
                                                                            dateDisplayFormat={"dd/MM/yyyy"}
                                                            /> */}
                                                            {/*
                
                                                                        <DateRangePicker
                                                                            weekStartDay="1"
                                                                            containerWidth="600px"
                                                                            textBoxWidth="400px"
                                                                            getDateRangeState={setUserState}
                                                                            applyFunc={() => console.log("Apply from parent")}
                                                                            cancelFunc={() => console.log("Cancel from parent")}
                                                                            dateFormatShow="DD/MM/YYYY"
                                                        /> */}
                                                                        <DatePicker selected={startDate} filterDate={filterWeekdays} className="form-control" placeholderText={t('Select Start date')} dateFormat="dd/MM/yyyy" minDate={minDate} maxDate={maxDate} onChange={(date) => handler(date)} />  
                                                                            <i className="fs-input-icon fa-solid fa-calendar" style={{ top: 6 }}></i> 
                                                                        </div>
                                                                        <div className="ls-inputicon-box" style={{marginLeft:"20px"}}>
                                                                        <h4>{t('End Date')}</h4>
                                                                           <DatePicker selected={endDate} className="form-control" dateFormat="dd/MM/yyyy" readOnly={true} />
                                                                        </div>
                                                                        {/* timeStamp && <p className="mb-0 text-end">{t('From')} {timeStamp}</p> */}
                                                                        {(isStepOneError && !startDate) ? <p className="text-danger mb-0">{t('Please select Start date')}</p> : ''}
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                            ) : ''}
                                                            {/*
                                                            {selectedDuration._id ? (
                                                                <div className="col-md-4">
                                                                    <h4>{t('End Date')}</h4>
                                                                    <div className="form-group">
                                                                        <div className="ls-inputicon-box">
                                                                           <DatePicker selected={endDate} className="form-control" dateFormat="dd/MM/yyyy" readOnly={true} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ) : ''} 
                                                            */}
                                                            {benefitsBy ? (
                                                                <div className="col-md-12">
                                                                    <div className="twm-candidates-grid-style1 p-0 border-0 form-group">
                                                                        <div className="twm-fot-content m-0">
                                                                            <div className="wt-post-info text-start">
                                                                                <div className="wt-post-text p-2">
                                                                                    <h4>{t('Benefits')} <span style={{ fontSize: '14px', fontWeight: 400 }}>({benefitsBy})</span></h4>
                                                                                </div>
                                                                                <ul className="description-list-2 m-0">
                                                                                    <li>
                                                                                        <i className="feather-thumbs-up"></i>
                                                                                        {t('Registration number can be changed')}
                                                                                    </li>
                                                                                    <li>
                                                                                        <i className="feather-thumbs-up"></i>
                                                                                        {t('Travel date can be changed')}
                                                                                    </li>
                                                                                    <li>
                                                                                        <i className="feather-thumbs-up"></i>
                                                                                        {t('Ability to cancel your vignette order')}
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                            <div className="twm-left-info">
                                                                                <div className="form-check mt-2 ">
                                                                                    <input className="form-check-input mt-2" type="checkbox" defaultChecked={flexiChange ? true : false} onClick={flexichargefun} id="flexCheckCheckedd" />
                                                                                    <label className="form-check-label text-them" htmlFor="flexCheckCheckedd">
                                                                                        <b>{t('Activate flex service for')} </b> <span style={{ fontSize: "17px" }}><b>{exchangeRates.absolute_value} {(flexiTepmChange * exchangeRates.price).toFixed(2)}</b></span>
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            ) : ''}

                                                            <div className="col-lg-12 col-md-12">
                                                                <div className="d-flex justify-content-between">
                                                                    <div>
                                                                        <button type="button" className="site-button" onClick={() => navigate("/home")}>{t('BACK')}</button>
                                                                    </div>
                                                                    <div className="text-right">
                                                                        {(selectedVehicle._id && selectedDuration._id && startDate) ?
                                                                            <button type="button" ref={nextButtonRef} className="site-button" onClick={() => changeStep(3)}>{t('NEXT')}</button> :
                                                                            <button type="button" disabled className="site-button btn-disabled" onClick={() => changeStep(3)}>{t('NEXT')}</button>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/*
                                                    <div className={"tab-pane " + `${stepTwo}`} id="profile" role="tabpanel">
                                                        <div className="col-lg-12 col-md-12 mt-3">
                                                            <div className="d-flex justify-content-between">
                                                                <div>
                                                                    <button type="button" className="site-button" onClick={() => backStep(1)}>{t('BACK')}</button>
                                                                </div>
                                                                <div>
                                                                    {(license_plate && license_plate.license_plate && license_plate.clicense_plate && startDate) ?
                                                                        <button type="button" className="site-button" onClick={() => changeStep(3)}>{t('NEXT')}</button> :
                                                                        <button type="button" disabled className="site-button btn-disabled" onClick={() => changeStep(3)}>{t('NEXT')}</button>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    */}
                                                    <div className={"tab-pane " + `${stepThree}`} id="messages" role="tabpanel">
                                                        <div>
                                                            <div className="form-group col-md-6 mb-3">
                                                            {!isLoggedIn &&
                                                                <span style={{fontSize: "18pt"}}>
                                                                <label><b>{t('Sign-up / Login using your e-mail')}</b></label>
                                                                <br/>
                                                                </span>
                                                            }
                                                                <small>{t('Your electronic vignette will be sent to this email address')}</small>
                                                                <input name="email" type="email" required="" className="form-control" value={userInfo.email} disabled={isLoggedIn} onChange={emailChange} placeholder="Email" />
                                                                {(isStepThreeError && !userInfo.email && !userInfo.guest_email) ? <p className="mb-0 text-danger" >Email address is mandatory</p> : ''}
                                                                {(isLoggedIn && showOtp) &&
                                                                    <span>
                                                                        <label>{t('Email Code')}</label>
                                                                        <input name="emailCode" type="text" className="form-control" placeholder="Email Code*" value={userInfo.emailCode} onChange={emailChange} />
                                                                    </span>
                                                                }
                                                            </div>
                                                        </div>

                                                        {!isLoggedIn &&
                                                            <div>
                                                                <div className="form-group col-md-6 mb-3">
                                                                    <label>{t('Confirm e-mail address')}</label>
                                                                    <input name="confirm_email" type="email" className="form-control" value={userInfo.confirm_email} onChange={emailChange} required="" placeholder="Confirm Email" autoComplete="new-email" />
                                                                    {(isStepThreeError && !userInfo.confirm_email && !userInfo.guest_email) ? <p className="mb-0 text-danger" >Confirm email is mandatory</p> : ''}
                                                                    {(isStepThreeError && (userInfo.email !== userInfo.confirm_email)) ? <p className="mb-0 text-danger" >Email and Confirm email not matching</p> : ''}
                                                                </div>
                                                            </div>
                                                        }
                                                        {!isLoggedIn &&
                                                            <div>
                                                                <div className="form-group col-md-6 mb-3 d-flex align-items-center">
                                                                    <div className="password-container">
                                                                    <label>{t('Password')}</label>
                                                                    <input name="password" 
                                                                    type={isPasswordVisible ? "text" : "password"} 
                                                                    className="form-control" 
                                                                    value={userInfo.password} 
                                                                    onChange={emailChange} required="" 
                                                                    placeholder="Password*" 
                                                                    autoComplete="new-password" />
                                                                    </div>
                                                                    <span style={{paddingLeft: "10px"}}>
                                                                    <label>&nbsp;</label>
                                                                    <i
                                                                        className={`fas fa-eye${isPasswordVisible ? "" : "-slash"} password-icon`}
                                                                        onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                                                                    ></i>
                                                                    </span>
                                                                    {showOtp &&
                                                                    <span>
                                                                        <label>{t('Email Code')}</label>
                                                                        <input name="emailCode" type="text" className="form-control" placeholder="Email Code*" value={userInfo.emailCode} disabled={isLoggedIn} onChange={emailChange} />
                                                                    </span>
                                                                    }
                                                                </div>
                                                                {(isStepThreeError && !userInfo.password && !userInfo.guest_email) ? <p className="mb-0 text-danger" >Password is mandatory</p> : ''}
                                                                {isPassOneError ? <p className="text-danger text-start">Password MUST contain at least 8 characters. MUST contain at least one number and one alphabet.</p> : ''}
                                                            </div>
                                                        }
                                                        {isValid && <p className="text-danger">Email address not valid</p>}
                                                        {/* 27-06 as per Br Nur, remove guest user for now - Start */}
                                                        {!isLoggedIn &&
                                                        <div>
                                                            <div className="form-group col-md-6 mb-3">
                                                                <span style={{fontSize: "18pt"}} className="centered-content">
                                                                <label><b>{t('Or continue as Guest')}</b></label>
                                                                <br/>
                                                                </span>
                                                                <small>{t('Your electronic vignette will be sent to this email address')}</small>
                                                                <input name="guest_email" type="email" required="" className="form-control" value={userInfo.guest_email} disabled={isLoggedIn} onChange={emailChange} placeholder="Guest Email" />
                                                            </div>
                                                        </div>
                                                        }
                                                        {/* 27-06 as per Br Nur, remove guest user for now - End  */}
                                                        {/* <div className="form-check pb-3">
                                                            <input className="form-check-input" type="checkbox" value="" id="flexCheckCheckede" />
                                                                <label className="form-check-label" htmlFor="flexCheckCheckede">
                                                                    Yes, I would like Express processing in under 2 hours for 6.00 extra
                                                                </label>
                                                        </div> */}

                                                        <div className="col-lg-12 col-md-12 border-top ">
                                                            <div className="form-check py-3">
                                                                <input className="form-check-input" type="checkbox" onChange={(e) => setAcceptTerms(e.target.checked)} id="IAcceptCheckedd" />
                                                                <label className="form-check-label" htmlFor="IAcceptCheckedd">
                                                                    {t('I accept the')} <a className="text-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop" style={{ cursor: "pointer" }} >{t('Terms and Conditions')}</a> {t('and for my data to be used for the purpose of order processing and vignette registration.(required)')}*
                                                                </label>
                                                            </div>
                                                            <div className="d-flex justify-content-between">
                                                                <div>
                                                                    <button type="button" className="site-button" onClick={() => backStep(1)}>{t('BACK')}</button>
                                                                </div>
                                                                <div>
                                                                    {((userInfo.email && userInfo.confirm_email && userInfo.password && acceptTerms) || (userInfo.guest_email && acceptTerms)) ?
                                                                        <button type="button" className="site-button" onClick={() => changeStep(4)}>{t('NEXT')}</button> :
                                                                        <button type="button" disabled className="site-button btn-disabled" onClick={() => changeStep(4)}>{t('NEXT')}</button>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={"tab-pane " + `${stepFour}`} id="messages" role="tabpanel">
                                                        {/* <div className="col-lg-12 col-md-12 border-top py-3">
                                                            <div className="d-flex">
                                                                <div className="text-left">
                                                                    <button type="button" className="site-button" onClick={() => backStep(3)}>{t('BACK')}</button>
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                        <div style={{display:"flex",flexWrap:"wrap"}}>
                                                        {(stepFour !== "" && showCheckout) ?
                                                            <div className="accordion tw-faq" id="sf-faq-accordion" style={{flex:"0 0 40%"}}>
                                                                <div className="mt-3 paypalbtn" onClick={handleShowPaymentForm} style={{cursor:"pointer"}}>
                                                                    <div className="wt-small-separator site-text-primary" style={{lineHeight:"40px",textAlign:"center", color: "white", backgroundColor: "black", width:"100%"}}>
                                                                        <div>
                                                                            {t('Pay by Card')}&nbsp;&nbsp;&nbsp;
                                                                            <i class="fa-brands fa-cc-mastercard fa-lg" style={{color: "#FFD43B"}}></i>
                                                                            &nbsp;
                                                                            <i class="fa-brands fa-cc-visa fa-lg"></i>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="mt-3 paypalbtn">     
                                                                <PayPal callback={processAfterPayment} loginUser={loginUser} amount={totalCost} 
                                                                        vehicleName={selectedVehicle.value} currency={exchangeRates.code}
                                                                        allInfo={{
                                                                            selectedDuration: selectedDuration,
                                                                            startDate: startDate,
                                                                            registrationCountry: registrationCountry,
                                                                            license_plate: license_plate,
                                                                            userInfo: userInfo,
                                                                            isBuyNow: isBuyNow,
                                                                            currency: exchangeRates.code,
                                                                            allVignettes: addlVignettes
                                                                        }}
                                                                        />
                                                                </div>
                                                                <div className="mt-3 paypalbtn">
                                                                    {showCheckout?(
                                                                    <Elements stripe={stripePromise}>
                                                                        <ApplePay callback={processAfterPayment} loginUser={loginUser} payAmount={totalCost.toFixed(2)} vehicleName={selectedVehicle.value} currencyCode={exchangeRates.code} countryCode={registrationCountry!=null?registrationCountry.code:""} 
                                                                        allInfo={{
                                                                            selectedDuration: selectedDuration,
                                                                            startDate: startDate,
                                                                            registrationCountry: registrationCountry,
                                                                            license_plate: license_plate,
                                                                            userInfo: userInfo,
                                                                            isBuyNow: isBuyNow,
                                                                            currency: exchangeRates.code,
                                                                            allVignettes: addlVignettes
                                                                        }}
                                                                        />
                                                                    </Elements>)
                                                                    :""}
                                                                </div>
                                                                <div className="mt-3 paypalbtn">
                                                                   {showCheckout?(
                                                                    <MyGooglePay callback={processAfterPayment} loginUser={loginUser} amount={totalCost} vehicleName={selectedVehicle.value} 
                                                                    allInfo={{
                                                                        selectedDuration: selectedDuration,
                                                                        startDate: startDate,
                                                                        registrationCountry: registrationCountry,
                                                                        license_plate: license_plate,
                                                                        userInfo: userInfo,
                                                                        isBuyNow: isBuyNow,
                                                                        currency: exchangeRates.code,
                                                                        allVignettes: addlVignettes
                                                                    }} 
                                                                    />
                                                                    ):""}
                                                                </div>
                                                            </div>
                                                            : ""}
                                                            <div style={{flex:"1 1 auto" }}>
                                                                <div className="">
                                                                    {/*
                                                                    <div className="wt-small-separator site-text-primary" style={{color: "blue"}}>
                                                                        <div>{t('Pay by Card')}</div>
                                                                    </div>
                                                                    */}
                                                                    {showPaymentForm &&
                                                                    <div className="accordion-item">
                                                                            <div id="FAQ1" className="" data-bs-parent="#sf-faq-accordion">
                                                                                <div className="accordion-body">
                                                                                    <div className="payment-form">
                                                                                        <div className="my-4" style={{display: "grid",placeItems:"center"}}>
                                                                                            <Elements stripe={stripePromise}>
                                                                                                <PaymentForm callback={processAfterPayment} loginUser={loginUser}
                                                                                                    allInfo={{
                                                                                                        selectedDuration: selectedDuration,
                                                                                                        selectedVehicle: selectedVehicle,
                                                                                                        startDate: startDate,
                                                                                                        registrationCountry: registrationCountry,
                                                                                                        destCountry: selectedCountry._id,
                                                                                                        processing: selectedCountry.processing,
                                                                                                        license_plate: license_plate,
                                                                                                        userInfo: userInfo,
                                                                                                        isBuyNow: isBuyNow,
                                                                                                        amount: totalCost,  // parseFloat(((selectedDuration.price + flexiChange + serviceCharge) * exchangeRates.price).toFixed(2)),
                                                                                                        currency: exchangeRates.code,
                                                                                                        giropay_payment_show : false,
                                                                                                        allVignettes: addlVignettes
                                                                                                    }} />
                                                                                            </Elements>
                                                                                            {/* giropay_payment_show: flexiObj.giropay_payment_show */}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        }
                                                                        {showSecretForm &&
                                                                        <div className="accordion-item">
                                                                            <div id="FAQ1" className="" data-bs-parent="#sf-faq-accordion">
                                                                                <div className="accordion-body">
                                                                                    <div className="payment-form">
                                                                                        <div className="my-4" style={{display: "grid",placeItems:"center"}}>
                                                                                            <SecretForm callback={processAfterPayment} loginUser={loginUser}
                                                                                                    allInfo={{
                                                                                                        selectedDuration: selectedDuration,
                                                                                                        selectedVehicle: selectedVehicle,
                                                                                                        startDate: startDate,
                                                                                                        registrationCountry: registrationCountry,
                                                                                                        destCountry: selectedCountry._id,
                                                                                                        processing: selectedCountry.processing,
                                                                                                        license_plate: license_plate,
                                                                                                        userInfo: userInfo,
                                                                                                        isBuyNow: isBuyNow,
                                                                                                        amount: totalCost, 
                                                                                                        currency: exchangeRates.code,
                                                                                                        giropay_payment_show : false,
                                                                                                        allVignettes: addlVignettes
                                                                                                    }} />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                        </div>                                                                                                              
                                                        <div className="d-flex mt-3">
                                                            <div className="text-left">
                                                                <button type="button" className="site-button" onClick={() => backStep(3)}>{t('BACK')}</button>
                                                                <span style={{cursor:"pointer"}} onClick={handleShowSecretForm}>&nbsp;&nbsp;&nbsp;</span>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                    {/* Apr 19 2024 - Added code to allow adding more vignettes for different countries - Start */}
                                                {(stepFour !== "" && showCheckout) ?
                                                <div className="accordion tw-faq" id="sf-faq-accordion" style={{flex:"0 0 60%",paddingTop:"15px"}}>
                                                    <div className="">
                                                    <div className="wt-small-separator site-text-primary twm-candidates-grid-style2" style={{color: "black"}}>
                                                        <p className="twm-candidate-address" style={{margin:0,"fontSize":"24px"}}>{t('Would you like to add another Vignette')}?
                                                        &nbsp;&nbsp;&nbsp;
                                                        <button type="button" className="site-button" style={{"fontSize":"24px"}} onClick={() => backToHome()}>{t('Yes')}</button>
                                                        </p>                                                        
                                                    </div>
                                                    </div>
                                                </div>:""
                                                }
                                            </div>
                                            <div className={stepFour !== "" ? 'col-lg-4 m-b30 ' : 'col-lg-4 col-md-12 m-b30'}>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="twm-jobs-grid-style1 mobile-m-order text-start">
                                                            <div className="twm-mid-content p-3 text-center">
                                                                <h4 className="m-0">{t('YOUR ORDER')}</h4>
                                                            </div>
                                                            {addlVignettes.length > 0 ? (
                                                                addlVignettes.map((addlItem, index) => (
                                                                    <div className="twm-right-content">
                                                                        {(index === 0)?
                                                                        <VignetteView 
                                                                        destCountry={t(addlItem.countryName)} 
                                                                        duration={t(addlItem.durationName)} 
                                                                        startDate={addlItem.startDate!=null?formatDate(new Date(addlItem.startDate)):""} 
                                                                        endDate={addlItem.endDate!=null?formatDate(new Date(addlItem.endDate)):""}
                                                                        countryCode={addlItem.regCountryCode||'NA'}
                                                                        flagUrl={addlItem.regCountryFlag||`/assets/images/place-holder.png`} 
                                                                        backColor={registrationCountry?.flag?.color||'white'} 
                                                                        plateNumber={addlItem.licensePlate}
                                                                        carImage={addlItem.vehicleImage} 
                                                                        price={addlItem.price} 
                                                                        destFlag={imageURL + addlItem.countryFilename} 
                                                                        index={index+1} 
                                                                        removeCallback={onRemoveVignette} />
                                                                        :
                                                                        <VignetteView 
                                                                        destCountry={t(addlItem.countryName)} 
                                                                        duration={t(addlItem.durationName)} 
                                                                        startDate={addlItem.startDate!=null?formatDate(new Date(addlItem.startDate)):""} 
                                                                        endDate={addlItem.endDate!=null?formatDate(new Date(addlItem.endDate)):""}
                                                                        countryCode={addlItem.regCountryCode||'NA'}
                                                                        flagUrl={addlItem.regCountryFlag||`/assets/images/place-holder.png`} 
                                                                        backColor={registrationCountry?.flag?.color||'white'} 
                                                                        plateNumber={addlItem.licensePlate}
                                                                        carImage={addlItem.vehicleImage} 
                                                                        price={addlItem.price} 
                                                                        destFlag={imageURL + addlItem.countryFilename} 
                                                                        index={index+1} 
                                                                        removeCallback={onRemoveVignette} />
                                                                        }
                                                                    </div>                                                                    
                                                                ))
                                                            ):null}
                                                            <hr></hr>
                                                            <div className="twm-right-content">
                                                                <div className="twm-jobs-amount m-0 mb-2 w-100"><span className="text-muted">{t('Vignette Cost')} :   </span> <span style={{ fontSize: "17px", float: 'right' }}><b>{exchangeRates.absolute_value} {vignetteCost.toFixed(2)}</b></span></div>
                                                            </div>
                                                            <div className="twm-right-content">
                                                                <div className="twm-jobs-amount m-0 mb-2 w-100"><span className="text-muted">{t('Flexi Service Charge')} :   </span> <span style={{ fontSize: "17px", float: 'right' }}><b>{exchangeRates.absolute_value} {getTotalFlexiCharges()}</b></span></div>
                                                            </div>
                                                            <div className="twm-right-content">
                                                                <div className="twm-jobs-amount m-0 mb-2 w-100"><span className="text-muted">{t('Service Charge')} :   </span> <span style={{ fontSize: "17px", float: 'right' }}><b>{exchangeRates.absolute_value} {serviceCharge.toFixed(2)}</b></span></div>
                                                            </div>
                                                            <div className="twm-candidates-grid-style1 p-0 border-0">
                                                                <div className="twm-fot-content">
                                                                    <div className="twm-left-info">
                                                                        <p className="twm-candidate-address">{t('Total Amount')}</p>
                                                                        <div className="twm-jobs-vacancies"><span style={{ fontSize: "17px" }}><b>{exchangeRates.absolute_value} {totalCost.toFixed(2)}</b></span></div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* {(stepFour !== "" && showCheckout) ?
                                                        <div className="text-center mt-4">
                                                            <StripeCheckout
                                                                stripeKey={stripeStripeKey}
                                                                token={handleToken}
                                                                amount={parseFloat((selectedDuration.price * exchangeRates.price).toFixed(2)) * 100} // amount in cents
                                                                name="Vignette Payment"
                                                                description="Purchase Vignette"
                                                                panelLabel="Pay Now"
                                                                currency={exchangeRates.code}
                                                                locale="auto"
                                                                email={userInfo.email}
                                                                allowRememberMe
                                                                billingAddress={false}
                                                                zipCode={false}
                                                            >
                                                                <button type="button" className="site-button" ><i className="feather-credit-card"></i> Pay Now</button>
                                                            </StripeCheckout>
                                                        </div> : ""
                                                        } */}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/*
                                    {(stepFour !== "" && showCheckout) ?
                                    <div className="twm-candidates-grid-style1 p-0 border-0" style={{flex:"0 0 60%"}}>
                                        <div className="twm-fot-content">
                                            <div className="twm-left-info">
                                                <p className="twm-candidate-address" style={{"fontSize":"24px"}}>{t('Would you like to purchase Vignette for same vehicle')}?
                                                &nbsp;&nbsp;&nbsp;
                                                <button type="button" className="site-button" style={{"fontSize":"24px"}} onClick={() => openPopup()}>{t('Yes')}</button>
                                                </p>
                                            </div>
                                        </div>
                                    </div> : ""
                                    }
                                    */}
                                    {showPopup && <AddlVigDialog onClose={closePopup} selVehicle={selectedVehicle} />}
                                    {/* Apr 19 2024 - Added code to allow adding more vignettes for different countries - End */}
                                </div>                                
                            </div>
                        </div>
                        <div className="wt-searchReasult-divider"></div>                    
                    </div>
                </div>
            </div>
            {isLoading &&
                <div className="sub-loading">
                    <div className="loader">
                        <span className="text-large font-weight-bolder">
                            <img src="assets/images/loader.svg" width={100} />
                        </span>
                    </div>
                </div>
            }
            {/* Modal */}
            <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" style={{marginTop:"10%",height:"70%"}}>
                <div className="modal-dialog modal-dialog-centered modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header border-none">

                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                             <TermsConditions />
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
