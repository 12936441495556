import React, {useEffect, useState, useContext} from 'react';
import {PaymentRequestButtonElement, useStripe, useElements} from '@stripe/react-stripe-js';
import StatusMessages, {useMessages} from './StatusMessages';
import { ApplePayButton } from "react-apple-pay-button";
import { successToast, warningToast, errorToast } from './toastify';
import { useTranslation } from 'react-i18next';
import { applepayPayment, digitollPost, createRecord } from "../Redux/Action/Action";
import Swal from 'sweetalert2';
import PopupDialog from './mypopup';
import AppContext from '../appContext';

const ApplePay = ({callback, loginUser, payAmount, vehicleName, currencyCode, countryCode, allInfo}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [messages, addMessage] = useMessages();
  const [showPopup, setShowPopup] = useState(false);
  const [selCurrency, setSelCurrency] = useState(null);
  const { t } = useTranslation();
  const { isLoggedIn, setIsLoggedIn, exchangeRates, defaultCurrency, orderSaveAsDraft, setOrderSaveAsDraft } = useContext(AppContext);
  let savePartnersSaleRes = null;

  useEffect(() => {
    setSelCurrency(currencyCode);
    console.log("ApplePay Currency has changed", currencyCode);
  }, [currencyCode])

  useEffect(() => {
    console.log("ApplePay New Currency set as", selCurrency);
    if (!stripe) {
      console.log("Stripe not active");
      return;
    }
    if (!elements) {
        console.log("Elements are not active");
        return;
    }
    console.log("payAmount as received",payAmount);
    if (isNaN(parseFloat(payAmount))) {
        console.log("PayAmount is NaN",payAmount);
        return;
    }
    const pr = stripe.paymentRequest({
      country: countryCode.toUpperCase(),
      currency: selCurrency.toLowerCase(),
      total: {
        label: 'Purhase of Auto Vignette for '+vehicleName,
        amount: (parseFloat((parseFloat(payAmount)*100).toFixed(0))),
      },
      requestPayerName: true,
      requestPayerEmail: true,
    });
    console.log("ApplePay paymentRequest created for "+selCurrency+" as",pr);
    pr.canMakePayment().then(result => {
      if (result) {
        console.log("ApplePay yes you may make payments",result);
        if (paymentRequest) {
           paymentRequest.update({
            currency: selCurrency.toLowerCase(),
            total: {
              label: 'Purhase of Auto Vignette for '+vehicleName,
              amount: (parseFloat((parseFloat(payAmount)*100).toFixed(0))),
            }
           });    
           console.log("ApplePay updated currency as ",paymentRequest.currency);
        }
        else {
          setPaymentRequest(pr);        
          console.log(">>> ApplePay set paymentRequest for first time");
        }
        console.log(">>> ApplePay after setPaymentRequest");
      }
      else {
        console.log("ApplePay No you can't make payments");
      }
    });
    pr.on('paymentmethod', async (e) => {
        
      console.log('>>>> ApplePay paymentmethod',e);
      
      let processPaymentAtEnd = false;
      let openPopupNeeded = false;
      let processDigitoll = false;
      let vigForBulgaria = null;

      // processPaymentAtEnd = true;
      // openPopupNeeded = true;

      if (allInfo.allVignettes != null && allInfo.allVignettes.length > 0) {
        setIsLoading(true);
        for (let i in allInfo.allVignettes) {
             let vigInfo = allInfo.allVignettes[i];
             console.log(">>> Now processing VigInfo",vigInfo);
             if (vigInfo.processing == 'PaymentOnly') {  // For PaymentOnly eg. Austria just complete the payment and invoke REST to send email
                processPaymentAtEnd = true;
                openPopupNeeded = true;
                continue;
             }
             if (vigInfo.processing !== 'PaymentAndDigitoll') {
                console.log("AAAAA - Unknown processing option or not implemented - "+vigInfo.processing+" for country "+vigInfo.countryName);
                continue;
             }
             processPaymentAtEnd = true;
             processDigitoll = true;
             vigForBulgaria = vigInfo;
        } // end of loop
      }  // end of if 
      let body = {};
      if (processPaymentAtEnd) {
          console.log(">>>> Now invoking BE ApplePay to create paymentIntent");
          const {clientSecret, error: backendError} = await applepayPayment(
            {
              vehicleName: vehicleName,
              paymentMethodType: 'card',
              currency: selCurrency.toLowerCase(),
              amount: payAmount
            }
          );      

          if (backendError) {
            console.log(">>>> Applepay backendError occured",backendError);
            addMessage(backendError.message);
            return;
          }

          console.log(">>>> ApplePay no errors so far");
          addMessage('Client secret returned');

          const {paymentIntent, error: confirmError} = await stripe.confirmCardPayment(
            clientSecret,
            {payment_method: e.paymentMethod.id},
            {handleActions: false}
          );

          if (confirmError) {
            console.log(">>>> ApplePay confirmCardPayment error");
            // Show error to your customer (e.g., insufficient funds)
            addMessage(confirmError.message);
            e.complete('fail');
            return;
          }

          console.log(">>>> ApplePay confirmCardpayment success #1");

          if (paymentIntent.status === "requires_action") {
              // Let Stripe.js handle the rest of the payment flow.
              const {error} = await stripe.confirmCardPayment(clientSecret);
              if (error) {
                console.log("ApplePay requires_action failed");
                addMessage("Sorry the selected payment method didn't work, please try again with another payment method, eg. Card Payment, Paypal etc");
                e.complete('fail');
                return;
              }
              else {
                  console.log("ApplePay requires_action succeeded");
              }
          }

          e.complete('success');

          // Show a success message to your customer
          // There's a risk of the customer closing the window before callback
          // execution. Set up a webhook or plugin to listen for the
          // payment_intent.succeeded event that handles any business critical
          // post-payment actions.
          addMessage(`Payment ${paymentIntent.status}: ${paymentIntent.id}`);
          if (openPopupNeeded) {
            openPopup();
          }
          body['applepay_order'] = paymentIntent;
      }
      //
      // For Bulgaria need to invoke Digitoll
      //
      if (processDigitoll) {
        console.log("AAAAA - Now processing digitoll for Destination country "+vigForBulgaria.countryName);
        digitollPost({
          "url": "user/authenticate",
          "delvyBody": {}
        }).then(async (tokenRes) => {
          if (tokenRes.status) {
            // Get digitoll products
            digitollPost({
              "token": tokenRes.data.token,
              "url": "products",
              "method": "GET",
            }).then(async (res1) => {
              let kapschProduct = {};
              if (res1.status) {
                for (let i in res1.data) {
                  if (res1.data[i].validityTypeText === vigForBulgaria.durationName) {
                      if (res1.data[i].vehicleType === 'car' && vigForBulgaria.vehicleName === 'Car') {
                        kapschProduct = res1.data[i];
                      }
                      if (res1.data[i].vehicleType === 'trailer' && vigForBulgaria.vehicleName !== 'Car') {
                        kapschProduct = res1.data[i];
                      }
                   }
                }
              } else {
                saveErrorLogs({
                  error: res1?.message,
                  function_name: "products",
                  type: "Digitoll products get",
                })
              }
              let startDate = new Date();
              if (vigForBulgaria.isBuyNow) {
                 startDate.setMinutes(startDate.getMinutes() + 2);
              } 
              else {
                 startDate = vigForBulgaria.startDate;
              }
              digitollPost({
                "token": tokenRes.data.token,
                "url": "partners/sale",
                "delvyBody": {
                  "posId": "123",
                  "saleRows": [
                    {
                      "activationDate": startDate.toISOString(),
                      "email": allInfo.userInfo.email,
                      "kapschProductId": kapschProduct.id,
                      "remoteClientId": "",
                      "vehicle": {
                        "countryCode": allInfo.registrationCountry.countryCode || "BG",
                        "lpn": allInfo.license_plate.license_plate
                      }
                    }
                  ]
                }
              }).then(async (partnersSaleRes) => {
                savePartnersSaleRes = partnersSaleRes;
                if (partnersSaleRes.status) {
                   processPaymentAtEnd = true;      
                   callback(body, "ApplePay", savePartnersSaleRes);
                } 
                else {
                  let errorMsg = "";
                  let errorMsg2 = "";
                  
                  if (partnersSaleRes?.message?.errors?.length) {
                    errorMsg = partnersSaleRes.message.errors[0].message;
                  }
                  if (partnersSaleRes.message.message) {
                    errorMsg2 = partnersSaleRes.message.message;
                  }
                  Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    titleText: errorMsg2 || 'Something went wrong',
                    text: errorMsg || 'Invalid License plate!',
                  })
                  errorToast("Order not placed");
                  saveErrorLogs({
                    error: partnersSaleRes?.message,
                    function_name: "Digitoll Place order",
                    type: "Digitoll (partners/sale)",
                  })
                  setIsLoading(false);
                }
              }, err => {
              })
            }, err => {
              setIsLoading(false);
            })
          } else {
            setIsLoading(false);
          }
        }, err => {
          setIsLoading(false);
        })        
      }
      else
      if (processPaymentAtEnd) {
         callback(body, "ApplePay", savePartnersSaleRes);
      }
    });
}, [selCurrency])

  const openPopup = () => {
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  useEffect(() => {
    if (!stripe) {
        console.log("Stripe not active");
      return;
    }
    if (!elements) {
        console.log("Elements are not active");
        return;
    }
    console.log("payAmount as received",payAmount);
    if (isNaN(parseFloat(payAmount))) {
        console.log("PayAmount is NaN",payAmount);
        return;
    }
    console.log("ApplePay currencyCode as received",currencyCode);
    console.log("ApplePay countryCode as received",countryCode);
  }, [stripe, elements, addMessage]);

  const handleApplePay = () => {     
    warningToast("Apple pay not supported on this device");
  }

  const saveErrorLogs = (data) => {
    // add
    const Body = {
      "record": {
        site: "Vignette frontend",
        error: JSON.stringify(data.error || {}),
        function_name: data.function_name,
        type: data.type,
        create_user: loginUser['_id'],
        status: "ACTV",
        deleted: null,
        deletedby: null,
      },
      "table": "error_logs",
      "uniqueFields": {},
      "uniqueFieldErrMsg": "Error in error_logs save"
    }

    createRecord(Body).then(async (res) => {
      setIsLoading(false)

    }, err => { setIsLoading(false) })
  }

  const updatePaymentRequestInstance = async () => {
    if (paymentRequest) {
      console.log("Now updatePaymentRequestInstance #1 ");
      paymentRequest.update({
        currency: selCurrency.toLowerCase(),
        total: {
          label: 'Purhase of Auto Vignette for '+vehicleName,
          amount: (parseFloat((parseFloat(payAmount)*100).toFixed(0))),
        }  
      });
      console.log("Now updatePaymentRequestInstance #2 ");
    }
  }

  return (
    <>
{/*
      <h4>Apple Pay</h4>
      <p>
        Before you start, you need to:
        <ul>
          <li><a href="https://stripe.com/docs/stripe-js/elements/payment-request-button#html-js-testing" target="_blank">Add a payment method to your browser.</a> For example, add a card to your Wallet for Safari.</li>
          <li>Serve your application over HTTPS. This is a requirement both in development and in production. One way to get up and running is to use a service like <a href="https://ngrok.com/" target="_blank" rel="noopener noreferrer">ngrok</a>.</li>
          <li><a href="https://stripe.com/docs/stripe-js/elements/payment-request-button#verifying-your-domain-with-apple-pay" target="_blank">Verify your domain with Apple Pay</a>, both in development and production.</li>
        </ul>
      </p>

      <a href="https://stripe.com/docs/stripe-js/elements/payment-request-button" target="_blank">Stripe Documentation</a>
  */}
      {(paymentRequest) ? (
      <PaymentRequestButtonElement options={{paymentRequest}} onClick={updatePaymentRequestInstance} />
      ): ""
      }
      {(!paymentRequest) && <ApplePayButton onClick={handleApplePay} />}
      <StatusMessages messages={messages} />
      {showPopup && <PopupDialog message={t("ApplePay Payment was successful, an email will be sent to you near the activation date or time. Please remember this activation may take upto 1 hour.")} onClose={closePopup} />}

{/*

      <p> <a href="https://youtu.be/bMCsJfJyQKA" target="_blank">Watch a demo walkthrough</a> </p>
  */}
    </>
  );
};

export default ApplePay;