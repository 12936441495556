import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { homedata } from "../Redux/Action/Action";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import data from '../data.json'; 

export default function Home() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [selectedCountry, setSelectedCountry] = useState({});
    const [countries, setCountries] = useState(data.Countries);

    const [isMobileSkeleton, setIsMobileSkeleton] = useState(false)

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        {countries.map((country) => (
            country.code === 'HU' && (
                setSelectedCountry(country)
              )
          ))}
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setIsMobileSkeleton(window.innerWidth <= 576)
        };

        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        console.log("selectedCountry",selectedCountry);
        if (selectedCountry.code === 'HU') {
            console.log("Now proceed Next With Hungary",selectedCountry);
            dispatch(homedata({
                country: selectedCountry._id,
                vehiclecategory: "",
                duration: ""
              }));
              let backToAddNewVignette = false;
              if (localStorage.getItem('saveAsDraft')) {
                let tempSaved = {};
                if (localStorage.getItem('saveAsDraft')) {
                    tempSaved = JSON.parse(localStorage.getItem('saveAsDraft'));
                }
                console.log("on Home page",tempSaved);
                if (tempSaved.backToAddNewVignette) {
                    backToAddNewVignette = true;
                    tempSaved['backToAddNewVignette'] = false;
                    localStorage.setItem("saveAsDraft", JSON.stringify(tempSaved));
                }
              }    
              if (!backToAddNewVignette) {
                  localStorage.removeItem('saveAsDraft');
              } 
              navigate("/order");          
        }
    }, [selectedCountry]);

    return (
        <></>
    )
}

