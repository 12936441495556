import React, { useState, useEffect, useRef, useContext } from "react";
import { Link } from 'react-router-dom';
// import { readRecordJoin } from "../Redux/Action/Action";
import AppContext from '../appContext';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import data from '../data.json'; 

let dd = true;
const Header = () => {
    const navigate = useNavigate();
    const { i18n, t } = useTranslation();
    const imageURL = "https://d2m4rx9jjmzfgf.cloudfront.net/";
    const [isSticky, setSticky] = useState(false);
    const [allCurrencies, setAllCurrencies] = useState(data.Currencies);
    const [selectedLnge, setLnge] = useState(data.Languages);
    const [userData, setUserData] = useState(null);
    const [headerCrncy, setHeaderCrncy] = useState("")
    const [headerLnge, setHeaderLnge] = useState("")
    const [headerLngeImage, setHeaderLngeImage] = useState("")
    const headerRef = useRef(null);
    const { setIsLoggedIn, loggedUser } = useContext(AppContext);
    let hasRun = useRef(false);

    const { isLoggedIn, setExchangeRates, setDefaultCurrency } = useContext(AppContext);

    const handleScroll = () => {
        if (headerRef.current) {
            if (window.pageYOffset > (headerRef.current.offsetTop + 80)) {
                setSticky(true);
            } else {
                setSticky(false);
            }
        }
    };

    useEffect(() => {
        if (!hasRun.current) {
            hasRun.current = true;
        }
        else {
            return;  // this is real important, otherwise already selected values are getting messed-up
        }
        console.log(">>>> refresh at header");
        window.addEventListener('scroll', handleScroll);
        if (localStorage.getItem('vignetteLoginUser')) {
            setUserData(JSON.parse(localStorage.getItem('vignetteLoginUser')));
        }
        let Body = {
            table1: "codedomains",
            table2: "codedomain_values",
            table1JoinColumn: "_id",
            table2JoinColumn: "codedomain",
            table1SelectFields: { "name": 1, "code": 1 },
            table2SelectFields: { "value": 1, "code": 1, "_id": 1, "absolute_value": 1, "filename": 1, "status": 1, "price": 1 },
            whereCondition: { "code": "LANGUAGE", "deleted": null, "codedomain_values.status": "ACTV" },
            sortBy: { "codedomain_values.position": 1 },
        }
        setHeaderLnge("Hungarian");
        setHeaderLngeImage("file-1685352956825.png");  // "file-1685352866392.png");
        i18n.changeLanguage("hu");
        let prevCurrency = localStorage.getItem('currency_storage');
        if (prevCurrency) {
            const selCurr = allCurrencies.find((oneCurr) => oneCurr.code === prevCurrency);
            if (selCurr) {
                setDefaultCurrency(selCurr);
                selectCurrency(selCurr); 
            }
        }
        else
        if (selectCurrency.length > 0) {
           setDefaultCurrency(allCurrencies[0]);
           selectCurrency(allCurrencies[0]);
        }
    }, [])

    function selectCurrency(item) {
        console.log(">>> Currency Changed",item);
        setHeaderCrncy(item.absolute_value + ' ' + item.value);
        setExchangeRates(item);
        localStorage.setItem('currency_storage', item.code);
    }

    function selectLanguage(item) {
        setHeaderLngeImage(item.filename);
        setHeaderLnge(item.value);
        i18n.changeLanguage(item.lng);
    }

    const logout = (e) => {
        e.preventDefault();
        setIsLoggedIn(false);
        localStorage.clear();
        navigate("/login");
    }

    return (
        <section>
            <header className='site-header header-style-3 mobile-sider-drawer-menu'>
                <div className="sticky-header main-bar-wraper navbar-expand-lg is-fixed ">
                    <div className={` ${isSticky ? 'main-bar color-fill' : 'main-bar headerbg'}`} ref={headerRef}>
                        <div className="container-fluid clearfix">
                            <div className="logo-header d-flex" style={{ width: '100%' }} >
                                <div className="logo-header-inner logo-header-one">
                                    <Link to="/home">
                                        <img src="/assets/images/logo-simple1.png" alt="" />
                                    </Link>
                                </div>
                                <div className="logo-header-inner logo-header-one">
                                <Link to="/home">
                                        <img src="/assets/images/hungary-logo.png" alt="" />
                                </Link>
                                </div>
                            </div>
                            <div className="nav-animation header-nav navbar-collapse d-flex justify-content-end">
                                <ul className=" nav navbar-nav">
                                    {/* currency */}
                                    <li className="has-child">
                                        <a>
                                            <span className="font-semibold d-flex align-items-center">
                                                <span className=""><span className="text-dark">{t(headerCrncy.slice(0, 2))}</span> <span>{t(headerCrncy.slice(2))}</span></span>
                                                <i className="feather-chevron-down"></i>
                                            </span>
                                        </a>
                                        <ul className="sub-menu">
                                            {allCurrencies.length && allCurrencies.map((item, index) => {
                                                return (
                                                    <li key={index}>
                                                        <a onClick={() => selectCurrency(item)}>
                                                           <span className="font-semibold">{t(item.value)}</span>
                                                            <span className="font-semibold text-dark"> {t(item.absolute_value)}</span>
                                                        </a>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </li>
                                    {/* language */}
                                    <li className="has-child modal-language">
                                        <a>
                                            <span className="font-semibold d-flex align-items-center">
                                                {headerLngeImage && <div className="flag" style={{ backgroundImage: `url(${imageURL}${headerLngeImage})` }}></div>}
                                                {t(headerLnge)} <i className="feather-chevron-down"></i>
                                            </span>
                                        </a>
                                        <ul className="sub-menu ">
                                            {selectedLnge.length && selectedLnge.map((items, index) => {
                                                return (
                                                    <li key={index}>
                                                        <a className="d-flex" onClick={() => selectLanguage(items)}>
                                                            <div className="flag" style={{ backgroundImage: `url(${imageURL}${items.filename})` }}></div>
                                                            {t(items.value)}
                                                            <span className="font-semibold"> {t(items.absolute_value)}</span>
                                                        </a>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                            {!isLoggedIn ? (
                                <div className="extra-cell mo_view_flexheader">
                                    <div className="nav-animation header-nav navbar-collapse d-flex justify-content-end">
                                        <ul className=" nav navbar-nav">
                                            {/* login registation */}
                                            <li className="has-child">
                                                <div className="extra-cell ps-0">
                                                    <div className="header-nav-btn-section">
                                                        <div className="twm-nav-btn-left">
                                                            <button type="button" className="twm-nav-sign-up site-button rounded-circle" style={{ backgroundColor: '#214236', padding: '11px', 'align-items': 'center', display: 'flex' }} >
                                                                <i className="feather-user p-0 m-0" style={{ fontSize: "22px" }}></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <ul className="sub-menu" style={{ right: "0", left: "auto" }}>
                                                    <li>
                                                        <Link to="/login">
                                                            <span className="font-semibold text-dark"> <i className="feather-log-in me-2" style={{ fontSize: "22px" }}></i>{t("Sign In")}</span>
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/registration">
                                                            <span className="font-semibold text-dark"><i className="feather-users me-2" style={{ fontSize: "22px" }}></i>{t("Registration")}</span>
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                    {/* <div className="header-nav-btn-section">
                                        <div className="twm-nav-btn-left me-2">
                                            <Link to="/login">
                                                <button type="button" className="twm-nav-sign-up site-button" style={{ backgroundColor: '#214236', fontWeight: 'inherit' }} >
                                                    <i className="feather-log-in"></i> Sign In
                                                </button>
                                            </Link>
                                        </div>
                                    </div> */}
                                    {/* <div className="header-nav-btn-section">
                                        <div className="twm-nav-btn-left">
                                            <Link to="/registration">
                                                <button type="button" className="twm-nav-sign-up site-button" style={{ fontWeight: 'inherit' }}>
                                                    <i className="feather-users"></i> Sign Up
                                                </button>
                                            </Link>
                                        </div>
                                    </div> */}
                                </div>) : (
                                <>
                                    {/* User Account logout*/}
                                    <div className="extra-cell">
                                        <div className="nav-animation header-nav navbar-collapse d-flex justify-content-end">
                                            <ul className=" nav navbar-nav">
                                                {/* logout */}
                                                <li className=" has-child">
                                                    <div className="extra-cell ps-0">
                                                        <div className="header-nav-btn-section">
                                                            <div className="twm-nav-btn-left text-center">
                                                                <Link to="/dashboard/my-profile" style={{display : 'flex', justifyContent: 'center'}}>
                                                                    <button type="button" className="twm-nav-sign-up site-button rounded-pill" style={{ backgroundColor: '#214236', padding: '11px', 'align-items': 'center' , display: 'flex'}} >
                                                                        <i className="feather-user p-0 m-0 d-flex justify-content-between" style={{ fontSize: "22px", fontWeight:'bold', }}></i>
                                                                        
                                                                    </button>
                                                                   
                                                                </Link>
                                                                {isLoggedIn && (<span className="hello-user">{t("Welcome")}<br/>{loggedUser?.email}</span>)}
                                                            </div>
                                                            
                                                        </div>
                                                       
                                                    </div>
                                                   
                                                    <ul className="sub-menu mt-2" style={{ right: "0", left: "auto" }}>
                                                        <li>
                                                            <Link to="/dashboard/my-profile">
                                                                <span className="font-semibold text-dark"><i className="feather-user me-2" style={{ fontSize: "22px" }}></i>{t("My Account")}</span>
                                                            </Link>
                                                        </li>

                                                        <li>
                                                            <Link onClick={logout}>
                                                                <span className="font-semibold text-dark"><i className="feather-log-out me-2" style={{ fontSize: "22px" }}></i>{t("Logout")}</span>
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                  
                                                </li>

                                            </ul>
                                        </div>
                                    </div>
                                    {/* Cart */}
                                    {/* <div className="extra-cell ps-0">
                                            <div className="header-nav-btn-section">
                                                <div className="twm-nav-btn-left">
                                                    <Link>
                                                        <button type="button" className="twm-nav-sign-up site-button rounded-circle" style={{ backgroundColor: '#214236', padding: '4px 15px',fontSize: '22px' }} >
                                                            <i className="feather-shopping-cart p-0"></i>
                                                        </button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div> */}
                                </>
                            )}
                            {/* </div> */}
                        </div>
                    </div>
                </div>
            </header>
            <div className="modal fade twm-sign-up" id="currencyModal" aria-hidden="true" aria-labelledby="currencyModalLabel" tabIndex="-1">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <form>

                            <div className="modal-header">
                                <h2 className="modal-title mb-0" id="sign_up_popupLabel">Currency</h2>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>

                            <div className="modal-body pt-0">
                                <div className="twm-tabs-style-2">
                                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                                        {allCurrencies.map((items, index) => {
                                            return (
                                                <li key={index} className={headerCrncy === items.absolute_value ? "nav-item active" : "nav-item"} role="presentation">
                                                    <button onClick={() => selectCurrency(items.absolute_value)} className={headerCrncy === items.absolute_value ? "nav-link active" : "nav-link"} data-bs-toggle="tab" data-bs-target="#currencyModal" type="button" data-bs-dismiss="modal">
                                                        {items.value}
                                                        <span className={headerCrncy === items.absolute_value ? "active-font-semibold" : "font-semibold"}> {items.absolute_value}</span>
                                                    </button>
                                                </li>
                                            )
                                        })}
                                    </ul>

                                    <div className="row">
                                        <div className="col-md-12 text-right">
                                            <button type="button" data-bs-dismiss="modal" aria-label="Close" className="site-button w-auto py-2" style={{ display: 'unset' }}>Close</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div className="modal fade twm-sign-up" id="languageModal" aria-hidden="true" aria-labelledby="languageModalLabel" tabIndex="-1">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <form>

                            <div className="modal-header">
                                <h2 className="modal-title mb-0" id="sign_up_popupLabel">Language</h2>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>

                            <div className="modal-body pt-0">
                                <div className="twm-tabs-style-2 modal-language">
                                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                                        {selectedLnge.map((items, index) => {
                                            return (
                                                <li key={index} className="nav-item" role="presentation">
                                                    <button onClick={() => selectLanguage(items.value)} className={headerLnge === items.value ? "nav-link d-flex active" : "nav-link d-flex"} data-bs-toggle="tab" data-bs-target="#sign-Employer" type="button" data-bs-dismiss="modal">
                                                        <div className="flag" style={{ backgroundImage: `url(${imageURL}${items.filename})` }}></div>
                                                        {items.value}
                                                    </button>
                                                </li>
                                            )
                                        })}
                                    </ul>

                                    <div className="row">
                                        <div className="col-md-12 text-right">
                                            <button type="button" data-bs-dismiss="modal" aria-label="Close" className="site-button w-auto py-2" style={{ display: 'unset' }}>Close</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Header;