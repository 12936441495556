import {
  PayPalScriptProvider,
  PayPalButtons,
} from "@paypal/react-paypal-js";
import React, { useState, useEffect } from "react";
import { successToast, errorToast } from '../components/toastify';
import { useTranslation } from 'react-i18next';
import { paypalPayment, digitollPost, createRecord } from "../Redux/Action/Action";
import Swal from 'sweetalert2';
import PopupDialog from './mypopup';
import envData from '../env.json'; 

const paypalClientId = envData.paypalClientId;

export default function PayPal({callback, loginUser, amount, vehicleName, currency, allInfo}) {
  const [isLoading, setIsLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [selCurrency, setSelCurrency] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    setSelCurrency(currency);
    console.log("Currency has changed", currency);
  }, [currency])

  useEffect(() => {
    console.log("New Currency set as", currency);
  }, [selCurrency])

  const openPopup = () => {
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const handleOnInit = () => {
    console.log("Paypal Button init using currency",selCurrency);
  }

  // FOR PayPalScriptProvider
  const createOrder = async (data, actions) => {

    const paymentUrl = await paypalPayment({
      type: 'createOrder',
      vehicle_name: vehicleName,
      amount: amount.toFixed(2),
      currency: currency,
    });
    console.log("Paypal CreateOrder",paymentUrl);
    if (!paymentUrl['status']) {
      console.log("error >>>", paymentUrl.message.error);
      setIsLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        titleText: paymentUrl.message.error || 'Something went wrong',
        text: paymentUrl.message.error_description || 'Invalid!',
      })
      return null;
    } else {
      console.log("Paypal CreateOrder success Id",paymentUrl['data'].id);
      return paymentUrl['data'].id;  // paymentUrl.data.id
    }
  };

  const onApprove = async (data, actions) => {
    console.log("OnApprove invoked");
    let processPaymentAtEnd = false;
    let openPopupNeeded = false;
    let savePartnersSaleRes = null;
    let vigForBulgaria = null;
    if (allInfo.allVignettes != null && allInfo.allVignettes.length > 0) {
      setIsLoading(true);
      for (let i in allInfo.allVignettes) {
           let vigInfo = allInfo.allVignettes[i];
           console.log(">>> Now processing VigInfo",vigInfo);
           if (vigInfo.processing == 'PaymentOnly') {  // For PaymentOnly eg. Austria just complete the payment and invoke REST to send email
              processPaymentAtEnd = true;
              openPopupNeeded = true;
              continue;
           }
           if (vigInfo.processing !== 'PaymentAndDigitoll') {
              console.log("AAAAA - Unknown processing option or not implemented - "+vigInfo.processing+" for country "+vigInfo.countryName);
              continue;
           }
           vigForBulgaria = vigInfo;
           processPaymentAtEnd = true;
      }
      if (processPaymentAtEnd) {
        if (vigForBulgaria != null) {
         let vigInfo = vigForBulgaria;
           console.log("AAAAA - Now processing digitoll for Destination country "+vigInfo.countryName);
           digitollPost({
            "url": "user/authenticate",
            "delvyBody": {}
          }).then(async (tokenRes) => {
            if (tokenRes.status) {
              // Get digitoll products
              digitollPost({
                "token": tokenRes.data.token,
                "url": "products",
                "method": "GET",
              }).then(async (res1) => {
                let kapschProduct = {};
                if (res1.status) {
                  for (let i in res1.data) {
                    if (res1.data[i].validityTypeText === vigInfo.durationName) {
                        if (res1.data[i].vehicleType === 'car' && vigInfo.vehicleName === 'Car') {
                          kapschProduct = res1.data[i];
                        }
                        if (res1.data[i].vehicleType === 'trailer' && vigInfo.vehicleName !== 'Car') {
                          kapschProduct = res1.data[i];
                        }
                     }
                  }
                } else {
                  saveErrorLogs({
                    error: res1?.message,
                    function_name: "products",
                    type: "Digitoll products get",
                  })
                }
                let startDate = new Date();
                if (vigInfo.isBuyNow) {
                   startDate.setMinutes(startDate.getMinutes() + 2);
                } 
                else {
                   startDate = vigInfo.startDate;
                }
                digitollPost({
                  "token": tokenRes.data.token,
                  "url": "partners/sale",
                  "delvyBody": {
                    "posId": "123",
                    "saleRows": [
                      {
                        "activationDate": startDate.toISOString(),
                        "email": allInfo.userInfo.email,
                        "kapschProductId": kapschProduct.id,
                        "remoteClientId": "",
                        "vehicle": {
                          "countryCode": allInfo.registrationCountry.countryCode || "BG",
                          "lpn": allInfo.license_plate.license_plate
                        }
                      }
                    ]
                  }
                }).then(async (partnersSaleRes) => {
                  savePartnersSaleRes = partnersSaleRes;
                  if (partnersSaleRes.status) {
                      processPaymentAtEnd = true;      
                      const ordConfirmRes = await paypalPayment({
                        type: 'confirmOrder',
                        orderId: data.orderID
                      });
                      console.log('Paypal confirmOrder return',ordConfirmRes);
                      if (ordConfirmRes['status']) {
                        let body = {};
                        successToast("Payment successful for Auto-vignette");
                        if (openPopupNeeded) {
                          openPopup();
                        }
                        body['paypal_order'] = ordConfirmRes['data'];
                        callback(body, "PayPal", savePartnersSaleRes);
                      } 
                      else {
                        console.log("error >>>>>>>>>>", ordConfirmRes.message.error);
                        setIsLoading(false);
                        errorToast("Payment failed for Auto-vignette");
                        Swal.fire({
                          icon: 'error',
                          title: 'Oops...',
                          titleText: ordConfirmRes.message.error || 'Something went wrong',
                          text: ordConfirmRes.message.error_description || 'Invalid!',
                        })
                        saveErrorLogs({
                          error: ordConfirmRes.message,
                          function_name: "payPalPayment",
                          type: "PayPal Payment",
                        })
                      } // else of error processing            
                  } 
                  else {
                    let errorMsg = "";
                    let errorMsg2 = "";
                    
                    if (partnersSaleRes?.message?.errors?.length) {
                      errorMsg = partnersSaleRes.message.errors[0].message;
                    }
                    if (partnersSaleRes.message.message) {
                      errorMsg2 = partnersSaleRes.message.message;
                    }
                    Swal.fire({
                      icon: 'error',
                      title: 'Oops...',
                      titleText: errorMsg2 || 'Something went wrong',
                      text: errorMsg || 'Invalid License plate!',
                    })
                    errorToast("Order not placed");
                    saveErrorLogs({
                      error: partnersSaleRes?.message,
                      function_name: "Digitoll Place order",
                      type: "Digitoll (partners/sale)",
                    })
                    setIsLoading(false);
                  }
                }, err => {
                })
              }, err => {
                setIsLoading(false);
              })
            } else {
              setIsLoading(false);
            }
          }, err => {
            setIsLoading(false);
          })
      } // if Bulgaria
      else {
          const ordConfirmRes = await paypalPayment({
            type: 'confirmOrder',
            orderId: data.orderID
          });
          console.log('Paypal confirmOrder return',ordConfirmRes);
          if (ordConfirmRes['status']) {
            let body = {};
            successToast("Payment successful for Auto-vignette");
            if (openPopupNeeded) {
              openPopup();
            }
            body['paypal_order'] = ordConfirmRes['data'];
            callback(body, "PayPal", savePartnersSaleRes);
          } 
          else {
            console.log("error >>>>>>>>>>", ordConfirmRes.message.error);
            setIsLoading(false);
            errorToast("Payment failed for Auto-vignette");
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              titleText: ordConfirmRes.message.error || 'Something went wrong',
              text: ordConfirmRes.message.error_description || 'Invalid!',
            })
            saveErrorLogs({
              error: ordConfirmRes.message,
              function_name: "payPalPayment",
              type: "PayPal Payment",
            })
          } // else of error processing
        } // else for Bulgaria
      }  // processPaymentAtEnd
    }
  };
  // END PayPalScriptProvider

  const saveErrorLogs = (data) => {
    // add
    const Body = {
      "record": {
        site: "Vignette frontend",
        error: JSON.stringify(data.error || {}),
        function_name: data.function_name,
        type: data.type,
        create_user: loginUser['_id'],
        status: "ACTV",
        deleted: null,
        deletedby: null,
      },
      "table": "error_logs",
      "uniqueFields": {},
      "uniqueFieldErrMsg": "Error in error_logs save"
    }

    createRecord(Body).then(async (res) => {
      setIsLoading(false)

    }, err => { setIsLoading(false) })
  }

  return (
    <>
      {selCurrency && selCurrency==='EUR' ? (
      <PayPalScriptProvider options={{components: "buttons",intent: "capture",currency: "EUR","client-id": paypalClientId}}>
        <PayPalButtons
          onInit={handleOnInit}
          forceReRender={[amount, vehicleName, allInfo, loginUser, callback, currency]}
          createOrder={(data, actions) => createOrder(data, actions)}
          onApprove={(data, actions) => onApprove(data, actions)}
          currency={selCurrency}
        />
      </PayPalScriptProvider>
      ): ""
      }
      {selCurrency && selCurrency==='USD' ? (
      <PayPalScriptProvider options={{components: "buttons",intent: "capture",currency: "USD","client-id": paypalClientId}}>
        <PayPalButtons
          onInit={handleOnInit}
          forceReRender={[amount, vehicleName, allInfo, loginUser, callback, currency]}
          createOrder={(data, actions) => createOrder(data, actions)}
          onApprove={(data, actions) => onApprove(data, actions)}
          currency={selCurrency}
        />
      </PayPalScriptProvider>
      ): ""
      }
      {selCurrency && selCurrency==='GBP' ? (
      <PayPalScriptProvider options={{components: "buttons",intent: "capture",currency: "GBP","client-id": paypalClientId}}>
        <PayPalButtons
          onInit={handleOnInit}
          forceReRender={[amount, vehicleName, allInfo, loginUser, callback, currency]}
          createOrder={(data, actions) => createOrder(data, actions)}
          onApprove={(data, actions) => onApprove(data, actions)}
          currency={selCurrency}
        />
      </PayPalScriptProvider>
      ): ""
      }
      {showPopup && <PopupDialog message={t("Paypal Payment was successful, an email will be sent to you near the activation date or time. Please remember this activation may take upto 1 hour.")} onClose={closePopup} />}
    </>
  );
}