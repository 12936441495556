
import React, { useState, useEffect, useContext } from "react";
import AppContext from '../appContext';
import { useTranslation } from 'react-i18next';
import './VignetteView.css'; 

const VignetteView = ({ destCountry, duration, startDate, endDate, countryCode, flagUrl, backColor, plateNumber, carImage, price, destFlag, index, removeCallback}) => {

  const { isLoggedIn, setIsLoggedIn, exchangeRates, defaultCurrency, orderSaveAsDraft, setOrderSaveAsDraft } = useContext(AppContext);
    const { t } = useTranslation();

    const onRemoveVignette = () => {
        removeCallback(index);
    };        

    function trimString(str) {
        return str.replace(/\s/g, "");
    } 
    
    return (
    <div>
      <span className="vignette-close-button" onClick={onRemoveVignette}>
        &nbsp;
      </span>
    <div className="vignette-view">
      <div className="vig-rectangle vig-first-rectangle">
          <h6 className="vehicle-heading">{t("Vehicle")}</h6>
          <div className="vig-top-section" style={{ backgroundColor: backColor }}> 
             <img src={flagUrl} alt="Country Flag"/>
             <span className="vig-top-label">{countryCode}</span>
          </div>    
          <div className="vig-middle-section"> 
            <span className="vig-vertical-text">{trimString(plateNumber)}</span>
          </div>
          <div className="vig-bottom-section"> 
            <img src={carImage} alt="Country Flag" />
          </div>
      </div>
      <div className="vig-dotted-line"></div>
      <div className="vig-rectangle vig-second-rectangle">
          <h6 className="vehicle-heading" style={{textAlign: "center"}}>{t("Vignette")}</h6>
          <div className="vignette-row">
            <span className="vig-value" style={{textAlign: "center"}}>
             <img src={destFlag} alt="Country Flag" style={{width: "20%"}}/>
             <p className="image-title">{destCountry}</p>
            </span>
          </div>
          <div className="vignette-row">
            <div className="vig-row-col1">
                <span className="vig-label">{t("Duration")}:</span>
                <span className="vig-value">{duration}</span>
            </div>
            <div className="vig-row-col2">
                <span className="vig-label">{t("Price")}:</span>
                <span className="vig-value">{exchangeRates.absolute_value} {(1 * price).toFixed(2)}</span>
            </div>
          </div>
          <div className="vignette-row">
           <div className="vig-row-col1">
                <span className="vig-label">{t("Start")}:</span>
                <span className="vig-value">{startDate}</span>
           </div>
           <div className="vig-row-col2">
                <span className="vig-label">{t("End")}:</span>
                <span className="vig-value">{endDate}</span>
           </div>
          </div>
      </div>
    </div>
    </div>
        );
  };

export default VignetteView;