// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from '../assets/translations/en.json';
import translationHU from '../assets/translations/hu.json';
import translationAR from '../assets/translations/ar.json';
import translationDE from '../assets/translations/de.json';
import translationTR from '../assets/translations/tr.json';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: translationEN,
    },
    ar: {
      translation: translationAR,
    },
    hu: {
      translation: translationHU,
    },
    de: {
      translation: translationDE,
    },
    tr: {
      translation: translationTR,
    },
  },
  lng: 'en', // Default language
  fallbackLng: 'en', // Fallback language
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
